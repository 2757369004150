<template>
  <div class="invisible-scrollbar">
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Tower Management
        </div>

        <!-- <v-col
          cols="12"
          md="12"
          style="display: flex; justify-content: flex-end"
        >
          <v-btn
            :color="'pink'"
            class="ma-2 white--text"
            @click="handleFileImport"
            :loading="isSelecting"
          >
            Import

            <v-icon right> mdi-import </v-icon>
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".csv"
            @change="onFileChange"
          />
          <v-btn color="red" class="ma-2 white--text" @click="Export()">
            Export
            <v-icon right> mdi-export </v-icon>
          </v-btn>
        </v-col> -->
        <v-card-text>
          <div>
            <v-card elevation="10" style="padding: 2%; margin-bottom: 2%">
              <v-row>
                <v-col cols="12" md="2" lg="2" class="mt-3">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2" lg="2" class="mt-3">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2" lg="2" class="mt-3">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeSearch"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" lg="2" class="mt-3">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-on:keyup.enter="SearchDataTower(search)"
                    v-model="search"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  lg="4"
                  class="mt-1"
                  style="text-align: end"
                >
                  <v-btn
                    :color="'blue'"
                    class="ma-2 white--text"
                    @click="SearchDataTower(search)"
                  >
                    Search

                    <v-icon right> mdi-magnify </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="permissionsPage.create"
                    color="green"
                    class="ma-2 white--text"
                    @click="checkDataButton('add', null)"
                  >
                    Create
                    <v-icon right> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="TowerList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.projectName }}</td>
                    <td class="header-table-css">{{ item.towerName }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.createTime) }}
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 150px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="checkDataButton('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveTowerByTowerID(item.towerID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    v-model="itemsPerPage"
                    label="10/page"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="!editCheck" class="text-h5">Create Tower</span>
          <span v-if="editCheck" class="text-h5">Edit Tower</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  label="Tower Name*"
                  v-model="towerName"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2" md="2">
                <v-switch
                  v-model="Switch"
                  hide-details
                  inset
                  :label="Switch ? 'Active' : 'InActive'"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="7" md="7" style="margin-top: 10px">
                <template>
                  <v-expansion-panels focusable v-model="addFloorName">
                    <v-expansion-panel v-for="(item, i) in 1" :key="i">
                      <v-expansion-panel-header>
                        <v-icon style="display: block"
                          >mdi-plus-box-multiple</v-icon
                        >Add Floor
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              label="Floor Name*"
                              v-model="floorName"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-hover v-slot="{ hover }">
                              <v-btn
                                color="green"
                                class="ma-2 white--text"
                                :elevation="hover ? 10 : 2"
                                :class="{ 'on-hover': hover }"
                                @click="addFloor()"
                              >
                                Add Floor
                                <v-icon right> mdi-plus </v-icon>
                              </v-btn>
                            </v-hover>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-col>
            </v-row>
          </v-container>
          <v-data-table
            :headers="headersFloor"
            :items="FloorList"
            hide-default-footer
            style="color: #da1818de"
            :items-per-page="itemsPerPage"
            :page.sync="page"
            single-line
            hide-details
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="header-table-css-center">
                  {{ (pageFloor - 1) * 10 + (index + 1) }}
                </td>
                <td class="header-table-css-center">
                  <!-- {{ item.floorName }} -->
                  <v-text-field
                    v-model="item.floorName"
                    :value="item.floorName"
                  ></v-text-field>
                </td>
                <!-- <td class="header-table-css">{{ item.activeFloor }}</td> -->
                <td class="header-table-css">
                  <v-switch v-model="item.activeFloor"></v-switch>
                </td>
                <td style="text-align: center">
                  <v-btn class="mx-2" fab dark small color="blue">
                    <v-icon dark @click="getZonedata(item, index)">
                      mdi-lan
                    </v-icon>
                  </v-btn>
                  <!-- <v-btn class="mx-2" fab dark small color="red">
                    <v-icon dark @click="deleteFloor(index)">mdi-trash-can-outline </v-icon>
                  </v-btn> -->
                </td>
              </tr>
            </template>
          </v-data-table>

          <div class="text-center">
            <v-pagination
              v-model="pageFloor"
              :length="lengthFloor"
              :total-visible="5"
              @input="ChangeFloorPage(pageFloor)"
            ></v-pagination>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                class="ma-2"
                style="min-width: 100px"
                :loading="loading4"
                :disabled="loading4"
                color="success"
                @click="addTower()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogZone" persistent max-width="800px">
      <v-card style="padding-top: 30px">
        <v-card-text>
          <template>
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-icon>mdi-office-building-outline</v-icon>
                <v-toolbar-title style="margin-left: 20px">
                  Floor Name : {{ floorNameShow }} <br />
                  Select Zone
                </v-toolbar-title>
              </v-toolbar>

              <v-row>
                <v-col>
                  <v-card-text>
                    <v-treeview
                      v-model="tree"
                      :load-children="fetch"
                      :items="itemsZone"
                      selected-color="indigo"
                      open-on-click
                      selectable
                      return-object
                      expand-icon="mdi-chevron-down"
                      on-icon="mdi-bookmark"
                      off-icon="mdi-bookmark-outline"
                      indeterminate-icon="mdi-bookmark-minus"
                    >
                    </v-treeview>
                  </v-card-text>
                </v-col>

                <v-divider vertical></v-divider>

                <v-col cols="12" md="6">
                  <v-card-text>
                    <div
                      v-if="tree.length === 0"
                      key="title"
                      class="text-h6 font-weight-light grey--text pa-4 text-center"
                    >
                      Select your zones
                    </div>

                    <v-scroll-x-transition group hide-on-leave>
                      <v-chip
                        v-for="(selection, i) in tree"
                        :key="i"
                        color="grey"
                        dark
                        small
                        class="ma-1"
                      >
                        <v-icon left small> mdi-beer </v-icon>
                        {{ selection.name }}
                      </v-chip>
                    </v-scroll-x-transition>
                  </v-card-text>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn text @click="tree = []"> Reset </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  class="ma-2"
                  style="min-width: 100px"
                  :loading="loading4"
                  :disabled="loading4"
                  color="success"
                  @click="addZone()"
                >
                  Save
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
                <v-btn
                  variant="outlined"
                  color="silver"
                  style="min-width: 100px"
                  append-icon="mdi-close-circle-outline"
                  @click="dialogZone = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="importTable" scrollable width="800px">
      <v-card>
        <v-card-title>Data File Import</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 620px; min-height: 610px">
          <v-data-table
            :headers="headersDropdown"
            :items="listdata"
            :items-per-page="10"
            class="elevation-2"
            :height="530"
          >
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="Import()"> Import </v-btn>
          <v-btn color="error" @click="importTable = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertComponent
      :flagAlert="AlertDialog"
      :typeAlert="TypeAlert"
      :mesAlert="MessageAlert"
      @flagClose="ClearDataAlert()"
    />
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import AlertComponent from "@/components/Alert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../main";

export default {
  name: "ManageTower",
  components: {
    AlertComponent,
  },
  data() {
    return {
      breweries: [],
      isLoading: false,
      tree: [],
      types: [],
      isSelecting: false,
      selectedFile: null,
      fileinput: "",
      permissionsPage: [],

      itemsZone: [
        {
          id: 0,
          name: "All Zones",
          children: [],
        },
      ],

      loader: null,
      loading4: false,
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      dialogZone: false,
      items: [10, 15, 20, 50, 100],
      valueDropdown: [],
      TowerList: [],
      FloorList: [],
      FloorListPage: [],
      Zone: [],
      ZoneList: [],
      pageCount: 0,
      image: null,
      page: 1,
      pageFloor: 1,
      itemFloor: 10,
      lengthFloor: 1,
      totalPage: 10,

      itemsPerPage: 10,
      search: "",
      showStatusSearch: null,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      Switch: false,

      memberID: "",
      personID: "",
      firstname: "",
      lastname: "",
      gender: "",
      idcard: "",
      passport: "",
      phone: "",
      email: "",
      note: "",
      tenantArray: [],

      projectName: "",
      towerId: "",
      towerName: "",
      startDate: "",
      endDate: "",
      tenant: "",
      menu: false,
      menu1: false,
      modal: false,
      menu2: false,
      editCheck: false,
      importTable: false,
      activeSearch: null,
      addFloorName: [0],
      floorName: "",
      floorNameShow: "",
      flooridSelect: null,
      indexlistFloor: 0,
      activeFloor: "",
      importAllData: [],
      listdata: [],
      header: "TowerName,CreateDate,ActiveStatus,Floor,Zone",
      headersDropdown: [
        {
          text: "id",
          align: "center",
          value: "id",
        },
        {
          text: "Tower Name",
          align: "left",
          value: "TowerName",
          sortable: false,
        },
        {
          text: "Create Date",
          align: "center",
          value: "CreateDate",
          sortable: false,
        },
        {
          text: "Active Status",
          align: "left",
          value: "ActiveStatus",
          sortable: false,
        },
        {
          text: "Floor",
          align: "left",
          value: "Floor",
          sortable: false,
        },
        {
          text: "Zone",
          align: "left",
          value: "Zone",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "ProjectName",
          align: "left",
          sortable: false,
        },
        {
          text: "TowerName",
          align: "left",
          sortable: false,
        },
        {
          text: "CreateDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersFloor() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
          class: "backgroundR",
        },
        {
          text: "Floor Name",
          align: "center",
          sortable: false,
        },
        {
          text: "Active",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    shouldShowTree() {
      return this.breweries.length > 0 && !this.isLoading;
    },
  },

  watch: {
    breweries(val) {
      this.types = val
        .reduce((acc, cur) => {
          const type = cur.brewery_type;

          if (!acc.includes(type)) acc.push(type);

          return acc;
        }, [])
        .sort();
    },
  },

  mounted() {
    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    this.GetDataTower();
    this.getZone();
  },

  methods: {
    handleFileImport() {
      this.isSelecting = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.uploader.click();
      // this.isSelecting = false;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createInput(files[0]);
    },
    createInput(file) {
      var that = this;
      that.listdata = [];
      let promise = new Promise((resolve) => {
        var reader = new FileReader();

        reader.onload = () => {
          // console.log(e);

          resolve((that.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          that.importAllData = result.split("\r\n");
          if (that.importAllData.length > 0) {
            for (let i = 1; i < that.importAllData.length; i++) {
              if (that.header != that.importAllData[0]) {
                return;
              } else {
                let tempData = that.importAllData[i].split(",");

                let fileList = {
                  TowerName: tempData[0],
                  CreateDate: tempData[1],
                  ActiveStatus: tempData[2],
                  Floor: tempData[3],
                  Zone: tempData[4],
                  id: i,
                };
                that.listdata.push(fileList);
              }
            }
            // console.log(that.listdata);
            // that.Import();
            that.importTable = true;
          } else {
            return;
          }
        },
        (error) => {
          /* handle an error */
          console.log(error);
        }
      );
    },
    fetch() {
      if (this.breweries.length) return;

      return (this.breweries = this.Zone);
    },

    getZonedata(data, index) {
      let that = this;
      that.indexlistFloor = index;
      that.flooridSelect = data.floorid;
      that.floorNameShow = data.floorName;
      that.tree = data.zoneList;
      that.dialogZone = true;
    },
    deleteFloor(index) {
      let that = this;
      that.FloorList.splice(index, 1);
    },
    addZone() {
      let that = this;
      that.FloorList[that.indexlistFloor].zoneList = that.tree;
      that.dialogZone = false;
      that.tree = [];
    },
    addFloor() {
      let that = this;
      if (that.floorName == "" || that.floorName == "") {
        Swal.fire({
          icon: "error",
          title: "Error...",
          width: 900,
          html: "Please insert 'Floor name'<br>กรุณากรอกชื่อชั้น",
        });
        return;
      }
      let data = {
        id: null,
        floorName: this.floorName,
        activeFloor: true,
        zoneList: [],
      };
      // that.pageFloor = 1;
      that.FloorListPage.push(data);
      that.floorName = "";
      // that.FloorList = that.paginate(that.FloorListPage, 10, that.itemFloor);
      let currentPage = Math.ceil(
        that.FloorListPage.length / that.itemsPerPage
      );
      that.FloorList = that.paginate(that.FloorListPage, 10, currentPage);
      that.pageFloor = currentPage;
      that.lengthFloor = currentPage;
      that.addFloorName = [0];
      this.$forceUpdate();
    },
    GetDataTower() {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: that.itemsPerPage,
        search: "",
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Tower/getTowerList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TowerList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getZone() {
      let that = this;
      axios
        .get(`${that.url}Tower/getZone`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.Zone = response.data.data;
            let children = [];
            that.Zone.forEach((a) => {
              let data = {
                id: a.zoneID,
                name: a.zoneName,
              };
              children.push(data);
            });

            that.itemsZone[0].children = children;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    SearchDataTower(data) {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: that.itemsPerPage,
        search: data == null ? "" : data,
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Tower/getTowerList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TowerList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    Export() {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        search: that.search,
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Tower/ExportDataTower`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            var tempdata = response.data.data;
            const blob = that.base64ToBlob(tempdata, "text/csv;charset=utf-8;");
            const linkSource = URL.createObjectURL(blob);
            // console.log(linkSource);
            var link = document.createElement("a");
            var date = new Date();
            link.download =
              "TowerReport_" + date.toLocaleString("th-TH") + ".csv";
            link.href = linkSource;
            link.click();
            that.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    Import() {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        listdata: that.listdata,
      };
      axios
        .post(`${that.url}Tower/ImportDataTower`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            that.importTable = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          if (response.data.status == 1) {
            that.LoadingDialog = false;
            that.importTable = false;
            Swal.fire({
              icon: "warning",
              title: "Please Check File Again",
              showConfirmButton: true,
              timer: 1500,
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let that = this;
      that.LoadingDialog = true;
      that.page = value;
      let temp = {
        page: value,
        perpage: that.itemsPerPage,
        search: that.search == null ? "" : that.search,
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Tower/getTowerList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TowerList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let that = this;
      that.LoadingDialog = true;
      that.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: "",
        startDate: that.startDate,
        endDate: that.endDate,
        active:
          that.activeSearch == "Active"
            ? true
            : that.activeSearch == null
            ? null
            : false,
      };
      axios
        .post(`${that.url}Tower/getTowerList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TowerList = response.data.data.listData;
            that.pageCount = Math.ceil(
              response.data.data.total / that.itemsPerPage
            );
            that.no_run = (that.page - 1) * that.itemsPerPage;
            that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },
    checkDataButton(type, data) {
      let that = this;
      if (type == "add") {
        that.editCheck = false;
        that.dialog = true;
        that.Switch = true;
        that.towerName = "";
        that.FloorList = [];
        that.pageFloor = 1;
        that.FloorListPage = [];
        that.FloorList = [];
        that.lengthFloor = 1;
      } else {
        that.dialog = true;
        // console.log(data);
        that.getTowerByTowerID(data.towerID);
      }
    },

    getTowerByTowerID(id) {
      let self = this;
      self.towerId = id;
      let temp = {
        towerID: id,
      };
      axios
        .post(`${self.url}Tower/getTowerByTowerID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.mapEdit(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    mapEdit(data) {
      let that = this;
      that.editCheck = true;
      that.dialog = true;
      that.towerName = data.towerName;
      that.Switch = data.activeStatus;
      that.pageFloor = 1;
      that.FloorListPage = data.floorList;
      that.FloorList = data.floorList;
      that.lengthFloor = Math.ceil(data.floorList.length / 10);
    },

    inActiveTowerByTowerID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        towerID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Role ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Tower/inActiveTowerByTowerID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.GetDataTower();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    addTower() {
      let that = this;
      that.LoadingDialog = true;
      // Check every floor must select zone
      if (that.validateFloorAndZone(that.FloorListPage) == false) {
        return;
      }
      if (!that.editCheck) {
        if (that.FloorListPage.length > 0) {
          let temp = {
            towerName: that.towerName,
            floorList: that.FloorListPage,
            activeStatus: that.Switch,
          };
          axios
            .post(`${that.url}Tower/addTower`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.dialog = false;
                that.LoadingDialog = false;
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.GetDataTower();
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
        } else {
          that.LoadingDialog = false;
          Swal.fire({
            icon: "error",
            title: "Fail...",
            width: 800,
            text: "Please Insert Floor.",
          });
        }
      } else {
        let temp = {
          towerID: that.towerId,
          towerName: that.towerName,
          floorList: that.FloorListPage,
          activeStatus: that.Switch,
        };
        axios
          .post(`${that.url}Tower/editTower`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              that.dialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.GetDataTower();
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    validateFloorAndZone(dataFloor) {
      let checkStatus = true;
      dataFloor.forEach((floor) => {
        if (checkStatus) {
          if (
            floor.activeFloor == true &&
            (floor.zoneList.length == 0 || floor.zoneList === null)
          ) {
            this.LoadingDialog = false;
            checkStatus = false;
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              html:
                "Please select a zone for " +
                floor.floorName +
                " floor<br>กรุณาเลือกโซนของชั้น " +
                floor.floorName,
            });
          }
        }
      });
      return checkStatus;
    },

    ChangeFloorPage(value) {
      let that = this;
      that.FloorList = that.paginate(that.FloorListPage, 10, value);
    },
    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },
    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}
.header-table-css-center {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.backgroundR {
  /* background-color: red; */
  background: red;
}
.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
