<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-card variant="outlined">
                <v-card-title style="font-weight: bolder">
                  <v-row>
                    <v-col cols="12" md="3"
                      >Welcome back
                      <span class="ml-5" style="color: #22577a">{{
                        username
                      }}</span></v-col
                    >
                    <v-col cols="12" md="6"
                      ><span>{{ CurrentDatetime }}</span></v-col
                    >
                    <v-col cols="12" md="3" style="text-align: right"
                      ><span style="color: #22577a">Residence</span></v-col
                    >
                  </v-row>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row style="margin-top: 2%">
            <v-col cols="12" md="3" sm="3">
              <v-card class="mt-3 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="blue"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                  height="70"
                >
                  <v-card-title class="text-h5" style="color: white">
                    Total Member
                    <v-icon class="ml-3" style="color: white"
                      >mdi-account-multiple</v-icon
                    >
                    :
                    {{
                      AddCommasNumber(
                        TotalNumberDataDashboard?.memberActive +
                          TotalNumberDataDashboard?.memberNotActive
                      )
                    }}
                  </v-card-title>
                </v-sheet>

                <v-card-subtitle style="color: #2196f3">
                  <v-progress-linear
                    striped
                    v-model="TotalMember"
                    color="blue"
                    height="20"
                  ></v-progress-linear>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Active :
                      <span style="font-size: large">{{
                        AddCommasNumber(TotalNumberDataDashboard?.memberActive)
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      InActive :
                      <span style="font-size: large">{{
                        AddCommasNumber(
                          TotalNumberDataDashboard?.memberNotActive
                        )
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-card class="mt-3 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="#ffae1f"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                  height="70"
                >
                  <v-card-title class="text-h5" style="color: #ffffff">
                    Total Tenant
                    <v-icon class="ml-3" color="white"
                      >mdi-briefcase-account</v-icon
                    >
                    :
                    {{
                      AddCommasNumber(
                        TotalNumberDataDashboard?.tenantActive +
                          TotalNumberDataDashboard?.tenantNotActive
                      )
                    }}
                  </v-card-title>
                </v-sheet>

                <v-card-subtitle style="color: #ffae1f">
                  <v-progress-linear
                    striped
                    v-model="TotalTenant"
                    color="#ffae1f"
                    height="20"
                  ></v-progress-linear>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Active :
                      <span style="font-size: large; color: #ffae1f">{{
                        AddCommasNumber(TotalNumberDataDashboard?.tenantActive)
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      InActive :
                      <span style="font-size: large; color: #ffae1f">{{
                        AddCommasNumber(
                          TotalNumberDataDashboard?.tenantNotActive
                        )
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-card class="mt-3 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="pink"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                  height="70"
                >
                  <v-card-title class="text-h5" style="color: white">
                    Member Entry Today
                    <v-icon class="ml-3" style="color: white"
                      >mdi-account-multiple</v-icon
                    >
                    :
                    {{ AddCommasNumber(responseMemberExit?.totalMember) }}
                  </v-card-title>
                </v-sheet>

                <v-card-subtitle style="color: #e27278">
                  <v-progress-linear
                    striped
                    v-model="Member"
                    color="pink"
                    height="20"
                  ></v-progress-linear>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Left Building :
                      <span style="font-size: large">{{
                        AddCommasNumber(responseMemberExit?.memberNotExist)
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Still Remain :
                      <span style="font-size: large">{{
                        AddCommasNumber(responseMemberExit?.memberExist)
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <v-card class="mt-3 mx-auto">
                <v-sheet
                  class="v-sheet--offset mx-auto"
                  color="green"
                  elevation="12"
                  max-width="calc(100% - 32px)"
                  height="70"
                >
                  <v-card-title class="text-h5" style="color: #ffffff">
                    Visitor Entry Today
                    <v-icon class="ml-3" color="white"
                      >mdi-briefcase-account</v-icon
                    >
                    :
                    {{
                      AddCommasNumber(
                        responsespInviteVisitorExistSummary?.totalVisitor
                      )
                    }}
                  </v-card-title>
                </v-sheet>

                <v-card-subtitle style="color: green">
                  <v-progress-linear
                    striped
                    v-model="Visitor"
                    color="green"
                    height="20"
                  ></v-progress-linear>

                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      Left Building :
                      <span style="font-size: large; color: green">{{
                        AddCommasNumber(
                          responsespInviteVisitorExistSummary?.visitorNotExist
                        )
                      }}</span>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      Still Remain :
                      <span style="font-size: large; color: green">{{
                        AddCommasNumber(
                          responsespInviteVisitorExistSummary?.visitorExist
                        )
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" sm="3"> </v-col>
          </v-row>
        </v-card-text>
      </div>

      <div>
        <v-row style="padding: 20px">
          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% -  32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Time Period
                </v-card-title>
              </v-sheet>
              <apexchart
                v-if="show"
                type="bar"
                height="500"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </v-card>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% -  32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Latest Entry : Past 7 Days
                </v-card-title>
              </v-sheet>
              <apexchart
                v-if="show"
                type="bar"
                height="500"
                :options="chartOptions2"
                :series="series2"
              ></apexchart>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="padding: 20px">
          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% - 32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Latest Entry
                </v-card-title>
              </v-sheet>
              <!-- <v-card-title> Latest Entry </v-card-title> -->
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-center">Type</th>
                      <th class="text-center">Tenant Name</th>
                      <th class="text-center">Tower Name</th>
                      <th class="text-center">DateTime</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in existTowerEntry" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.existType }}</td>
                      <td class="text-center">{{ item.tenantName }}</td>
                      <td class="text-center">{{ item.towerName }}</td>
                      <td>{{ ChangeFormatDate(item.exitDatetime) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>

          <v-col cols="6" md="6" sm="6">
            <v-card class="mt-3 mx-auto">
              <v-sheet
                class="v-sheet--offset mx-auto"
                color="blue"
                elevation="12"
                max-width="calc(100% - 32px)"
                height="70"
              >
                <v-card-title class="text-h5" style="color: white">
                  Latest Exit
                </v-card-title>
              </v-sheet>
              <!-- <v-card-title> Latest Exit </v-card-title> -->
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-center">Type</th>
                      <th class="text-center">Tenant Name</th>
                      <th class="text-center">Tower Name</th>
                      <th class="text-center">DateTime</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in existTowerExit" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.existType }}</td>
                      <td class="text-center">{{ item.tenantName }}</td>
                      <td class="text-center">{{ item.towerName }}</td>
                      <td>{{ ChangeFormatDate(item.exitDatetime) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- <div class="mb-3">
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-card class="ml-4 mr-4" color="#5D87FF" dark>
              <v-card-title class="text-h5"> List </v-card-title>
              <v-card-subtitle>1234</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </div> -->
    </v-card>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "DashboardResidence",
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      dialog: false,
      show: false,
      items: [10, 15, 20, 50, 100],
      pageCount: 0,
      image: null,
      page: 1,
      itemsPerPage: 10,
      search: "",
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      TotalNumberDataDashboard: [],
      responseMemberExit: [],
      responsespInviteVisitorExistSummary: [],

      existTower: [],
      existTowerEntry: [],
      existTowerExit: [],
      responseTime: [],
      responseDate: [],
      username: localStorage.getItem("Username"),
      TotalTenant: 0,
      TotalMember: 0,
      Member: 0,
      Visitor: 0,
      dateNow: "",
      CurrentDatetime: "",
      timerCount: 10,

      reportIn: [
        {
          name: "Frozen Yogurt",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Ice cream sandwich",
          exitStatus: 0,
          type: "Visitor",
          date: "19/02/2024",
        },
        {
          name: "Eclair",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Cupcake",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Gingerbread",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Jelly bean",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Lollipop",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Honeycomb",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Donut",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "KitKat",
          exitStatus: 0,
          type: "Member",
          date: "19/02/2024",
        },
      ],
      reportOut: [
        {
          name: "Frozen Yogurt",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Ice cream sandwich",
          exitStatus: 1,
          type: "Visitor",
          date: "19/02/2024",
        },
        {
          name: "Eclair",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Cupcake",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Gingerbread",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Jelly bean",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Lollipop",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Honeycomb",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "Donut",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
        {
          name: "KitKat",
          exitStatus: 1,
          type: "Member",
          date: "19/02/2024",
        },
      ],
      labels1: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value1: [200, 675, 410, 390, 310, 460, 250, 240],
      chartOptionsdonut: {
        labels: ["Apple", "Mango", "Orange", "Watermelon"],
      },
      seriesdonut: [44, 55, 41, 17, 15],
      series: [
        {
          name: "Entry",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //14
        },
        {
          name: "Exit",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], //14
        },
      ],
      series2: [
        {
          name: "Entry",
          data: [29, 40, 35, 30, 44, 30, 20], //7
        },
        {
          name: "Exit",
          data: [80, 60, 70, 89, 75, 93, 40], //7
        },
      ],

      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
        },
        colors: ["#008000", "#FF0000"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: "Time Period",
          },
          categories: [
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
          ],
        },
        yaxis: {
          title: {
            text: "User",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " times";
            },
          },
        },
      },
      chartOptions2: {
        chart: {
          type: "bar",
          height: 350,
        },
        colors: ["#008000", "#FF0000"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          title: {
            text: "Datetime",
          },
          categories: [
            "2024-02-15",
            "2024-02-16",
            "2024-02-17",
            "2024-02-18",
            "2024-02-19",
            "2024-02-20",
            "2024-02-21",
          ],
        },
        yaxis: {
          title: {
            text: "User",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " times";
            },
          },
        },
      },
    };
  },

  // watch: {
  //   timerCount: {
  //     handler(value) {
  //       if (value > 0) {
  //         setTimeout(() => {
  //           this.timerCount--;
  //           console.log(this.timerCount);

  //           if (value == 0) {
  //             this.timerCount = 0;
  //           }
  //         }, 1000);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },

  mounted() {
    let that = this;
    that.dateNow = new Date();
    that.getTotalNumberDataDashboard("1");
    setInterval(() => {
      that.getCurrentDate();
    }, 1000);
  },

  methods: {
    AddCommasNumber(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    getTotalNumberDataDashboard(data) {
      let that = this;
      if (data == "1") {
        that.LoadingDialog = true;
      }

      axios
        .get(`${that.url}Dashboard/getTotalNumberDataDashboard`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.TotalNumberDataDashboard =
              response.data.data.responseMemberAndTenant;
            that.responseMemberExit = response.data.data.responseMemberExit;
            that.responsespInviteVisitorExistSummary =
              response.data.data.responsespInviteVisitorExistSummary;
            that.existTower = response.data.data.existTower;
            that.existTowerEntry = that.existTower.filter(
              (a) => a.isExist == true
            );
            that.existTowerExit = that.existTower.filter(
              (a) => a.isExist == false
            );

            that.TotalTenant =
              (that.TotalNumberDataDashboard.tenantActive /
                (that.TotalNumberDataDashboard.tenantActive +
                  that.TotalNumberDataDashboard.tenantNotActive)) *
              100;
            that.TotalMember =
              (that.TotalNumberDataDashboard.memberActive /
                (that.TotalNumberDataDashboard.memberActive +
                  that.TotalNumberDataDashboard.memberNotActive)) *
              100;

            that.Visitor =
              (that.responsespInviteVisitorExistSummary.visitorNotExist /
                (that.responsespInviteVisitorExistSummary.visitorNotExist +
                  that.responsespInviteVisitorExistSummary.visitorExist)) *
              100;
            that.Member =
              (that.responseMemberExit.memberNotExist /
                that.responseMemberExit.totalMember) *
              100;

            that.responseTime = response.data.data.responseTime;
            that.responseDate = response.data.data.responseDate;

            that.getPeriod(that.responseTime);
            that.getPeriodDate(that.responseDate);

            // that.series.forEach((element, index) => {
            //   if (index == 0) {
            //     element.data[0] = that.TotalNumberDataDashboard.memberActive;
            //     element.data[1] = that.TotalNumberDataDashboard.tenantActive;
            //     element.data[2] = that.TotalNumberDataDashboard.userActive;
            //   }
            //   if (index == 1) {
            //     element.data[0] = that.TotalNumberDataDashboard.memberNotActive;
            //     element.data[1] = that.TotalNumberDataDashboard.tenantNotActive;
            //     element.data[2] = that.TotalNumberDataDashboard.userNoActive;
            //   }
            // });
            that.LoadingDialog = false;
            that.show = true;
            if (data == "1") {
              that.refreshDashboard();
            }
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
          // eventBus.$emit('logout');
        });
    },

    refreshDashboard() {
      let that = this;
      var refresh = setInterval(() => {
        if (localStorage.getItem("page") == "Dashboard") {
          that.getTotalNumberDataDashboard("2");
          that.timerCount = 10;
        } else {
          clearInterval(refresh);
        }
      }, 10000);
    },

    logout() {
      eventBus.$emit("logout");
    },

    getPeriod(data) {
      let that = this;

      that.series[0].data[0] =
        data.find((x) => x.hour == "06") == null
          ? 0
          : data.find((x) => x.hour == "06").entry;
      that.series[0].data[1] =
        data.find((x) => x.hour == "07") == null
          ? 0
          : data.find((x) => x.hour == "07").entry;
      that.series[0].data[2] =
        data.find((x) => x.hour == "08") == null
          ? 0
          : data.find((x) => x.hour == "08").entry;
      that.series[0].data[3] =
        data.find((x) => x.hour == "09") == null
          ? 0
          : data.find((x) => x.hour == "09").entry;
      that.series[0].data[4] =
        data.find((x) => x.hour == "10") == null
          ? 0
          : data.find((x) => x.hour == "10").entry;
      that.series[0].data[5] =
        data.find((x) => x.hour == "11") == null
          ? 0
          : data.find((x) => x.hour == "11").entry;
      that.series[0].data[6] =
        data.find((x) => x.hour == "12") == null
          ? 0
          : data.find((x) => x.hour == "12").entry;
      that.series[0].data[7] =
        data.find((x) => x.hour == "13") == null
          ? 0
          : data.find((x) => x.hour == "13").entry;
      that.series[0].data[8] =
        data.find((x) => x.hour == "14") == null
          ? 0
          : data.find((x) => x.hour == "14").entry;
      that.series[0].data[9] =
        data.find((x) => x.hour == "15") == null
          ? 0
          : data.find((x) => x.hour == "15").entry;
      that.series[0].data[10] =
        data.find((x) => x.hour == "16") == null
          ? 0
          : data.find((x) => x.hour == "16").entry;
      that.series[0].data[11] =
        data.find((x) => x.hour == "17") == null
          ? 0
          : data.find((x) => x.hour == "17").entry;
      that.series[0].data[12] =
        data.find((x) => x.hour == "18") == null
          ? 0
          : data.find((x) => x.hour == "18").entry;
      that.series[0].data[13] =
        data.find((x) => x.hour == "19") == null
          ? 0
          : data.find((x) => x.hour == "19").entry;
      that.series[0].data[14] =
        data.find((x) => x.hour == "20") == null
          ? 0
          : data.find((x) => x.hour == "20").entry;

      that.series[1].data[0] =
        data.find((x) => x.hour == "06") == null
          ? 0
          : data.find((x) => x.hour == "06").exit;
      that.series[1].data[1] =
        data.find((x) => x.hour == "07") == null
          ? 0
          : data.find((x) => x.hour == "07").exit;
      that.series[1].data[2] =
        data.find((x) => x.hour == "08") == null
          ? 0
          : data.find((x) => x.hour == "08").exit;
      that.series[1].data[3] =
        data.find((x) => x.hour == "09") == null
          ? 0
          : data.find((x) => x.hour == "09").exit;
      that.series[1].data[4] =
        data.find((x) => x.hour == "10") == null
          ? 0
          : data.find((x) => x.hour == "10").exit;
      that.series[1].data[5] =
        data.find((x) => x.hour == "11") == null
          ? 0
          : data.find((x) => x.hour == "11").exit;
      that.series[1].data[6] =
        data.find((x) => x.hour == "12") == null
          ? 0
          : data.find((x) => x.hour == "12").exit;
      that.series[1].data[7] =
        data.find((x) => x.hour == "13") == null
          ? 0
          : data.find((x) => x.hour == "13").exit;
      that.series[1].data[8] =
        data.find((x) => x.hour == "14") == null
          ? 0
          : data.find((x) => x.hour == "14").exit;
      that.series[1].data[9] =
        data.find((x) => x.hour == "15") == null
          ? 0
          : data.find((x) => x.hour == "15").exit;
      that.series[1].data[10] =
        data.find((x) => x.hour == "16") == null
          ? 0
          : data.find((x) => x.hour == "16").exit;
      that.series[1].data[11] =
        data.find((x) => x.hour == "17") == null
          ? 0
          : data.find((x) => x.hour == "17").exit;
      that.series[1].data[12] =
        data.find((x) => x.hour == "18") == null
          ? 0
          : data.find((x) => x.hour == "18").exit;
      that.series[1].data[13] =
        data.find((x) => x.hour == "19") == null
          ? 0
          : data.find((x) => x.hour == "19").exit;
      that.series[1].data[14] =
        data.find((x) => x.hour == "20") == null
          ? 0
          : data.find((x) => x.hour == "20").exit;
    },

    getPeriodDate(data) {
      let that = this;
      // console.log(data);
      let categories = [];

      data.forEach((value, index) => {
        categories.push(value.transactionDate);

        that.series2[0].data[index] = value.entry;

        that.series2[1].data[index] = value.exit;
      });

      that.chartOptions2.xaxis.categories = categories;
      // series2
    },

    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    getCurrentDate() {
      let self = this;
      let newDate = new Date();
      let date =
        newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year =
        newDate.getFullYear() < 2500
          ? newDate.getFullYear() + 543
          : newDate.getFullYear();
      let hours =
        newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
      let minutes =
        newDate.getMinutes() < 10
          ? "0" + newDate.getMinutes()
          : newDate.getMinutes();
      let seconds =
        newDate.getSeconds() < 10
          ? "0" + newDate.getSeconds()
          : newDate.getSeconds();
      self.CurrentDatetime = `${date}/${
        month < 10 ? `0${month}` : `${month}`
      }/${year}  ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  /* background-color: #9AC5F4 !important; */
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
