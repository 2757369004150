import Vue from 'vue'
import JSZip from 'jszip'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'; 
import FlagIcon from 'vue-flag-icon';
import axios from 'axios';
import store from "./store";
import i18n from '@/plugins/i18n';
import Swal from 'sweetalert2'
import VueCookies from 'vue-cookies';
import Carousel3d from 'vue-carousel-3d';
import VueExcelXlsx from "vue-excel-xlsx";
import VueQRCodeComponent from 'vue-qrcode-component'
import '@/plugins/apexcharts'

Vue.prototype.$http = axios;

const token = localStorage.getItem('Token');
const isLoggedIn = localStorage.getItem('isLoggedIn') == "true" ? true : false;
if (token != "" && isLoggedIn == true) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = "Bearer " + token
}

Vue.component('qr-code', VueQRCodeComponent);
window.$ = window.jQuery = require("jquery");
Vue.config.productionTip = false
Vue.use(FlagIcon);
Vue.use(JSZip)
Vue.use(VueCookies);
Vue.use(Carousel3d);
Vue.use(VueExcelXlsx);
export const eventBus = new Vue();

new Vue({
  i18n,
  vuetify,
  store,
  router,
  Swal,
  render: h => h(App)
}).$mount('#app')
