<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" style="margin-top: 10%;">
        <label
          style="font-size: 70px; display: flex; justify-content: space-around"
          >Accept Certificate  </label
        >
      </v-col>
      <v-col cols="12" md="12">
        <label
          style="font-size: 70px; display: flex; justify-content: space-around"
          >Successfully</label
        ><br><label></label>
      </v-col>
 
    </v-row>

    <v-footer
      color="#1d2939"
      :absolute="$vuetify.breakpoint.height < 786 ? true : true"
      class="white--text text-caption footer"
    >
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2">
              <v-img
                src="@/assets/logofs.svg"
                width="80"
                class="mx-auto mt-1"
              ></v-img>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="8">
              <div class="d-flex mt-2">
                <span style="font-size: small; margin-bottom: 5px; color: white"
                  >Copyright © 2024 Forward System Co. Ltd.</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2"> </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import enurl from "@/api/environment";
// import Swal from "sweetalert2/dist/sweetalert2.js";
// import LoadingComponent from "@/components/Loading";
export default {
  name: "Login",
  components: {
    // LoadingComponent,
  },
  data() {
    return {
      model: "tab-1",
      url: enurl.apiUrl,
      LoadingDialog: false,
      flagPassword: false,
      // username: "admin",
      // password: "fscp_1",
      username: "",
      password: "",
      // PathLogo: require("@/assets/logo-FS.svg"),
      PathLogo: require("@/assets/LogoFinedayFullname.png"),
      expiryDate: "",
    };
  },

  mounted: function () {
    var isLoggedIn = this.$cookies.get("isLoggedIn");
    localStorage.setItem("isLoggedIn", isLoggedIn);
    // var isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn == "true") {
      let token = this.$cookies.get("Token");

      localStorage.setItem("Token", token);
    }
  },

  methods: {


  },
};
</script>
<style scoped>
.login {
  width: 14rem;
}

.form {
  min-width: 50%;
}

.img-intersection {
  width: 100%;
  height: 100vh;
  max-width: 100%;
}

@media only screen and (max-width: 959px) {
  .logocss {
    display: none;
  }
  .rescss {
    margin: 1rem;
    width: 100% !important;
  }
}
.centered-input-header >>> input {
  text-align: center;
  color: black;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}
.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}
.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
