const environment = {
    // apiUrl: 'https://localhost:44325/api/',  // local
    // apiCarparkUrl: 'https://localhost:44325/api/',  // Carpark local

    apiUrl: 'https://fineday-api.objective-faraday.119-59-118-127.plesk.page/api/',  // VPS Server
    apiCarparkUrl: 'https://fineday-api.objective-faraday.119-59-118-127.plesk.page/api/'  // OB Carpark prod Server

    // apiUrl: 'https://10.145.200.226/api/'  // OB dev Server

    // apiUrl: 'https://10.145.204.34/api/',  // OB uat Server
    // apiCarparkUrl: 'https://10.145.200.226:444//api/'  // OB Carpark uat Server
    
    // apiUrl: 'https://10.144.52.130/api/',  // OB prod Server
    // apiCarparkUrl: 'https://10.144.66.34:444/api/'  // OB Carpark prod Server
}
export default environment