<template>
    <v-footer
      color="#1D2939"
      style="margin-top: auto;"
      class="white--text text-caption hidden-md-and-down"
    >
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2"></v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="1">
              <v-img
                src="@/assets/logofs.svg"
                width="80"
                class="mx-auto mt-1"
              ></v-img>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="3">
              <div class="mt-2">
                <span style="font-size: small; margin-bottom: 5px; color: white"
                  >Copyright © 2024 Forward System Co. Ltd.</span
                >
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="6"></v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
</template>
<script>

export default {
  data() {
    return {
    };
  },

  
};
</script>
