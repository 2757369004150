<template>
  <v-container style="max-width: 100vw; height: 60vh">
    <v-row
      style="
        display: flex;
        justify-content: end;
        margin-right: 30px;
        margin-top: -60px;
      "
    >
      <!-- <v-card width="200" height="70" elevation="10" >
        <v-img
              :src="reportImg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="70px"
            >
            <v-card-title class="justify-center" style="font-size: 22px;" v-text="'Report'"></v-card-title>
             
            </v-img>
      </v-card> -->
      <!-- <v-btn
        v-if="reportMenu.length > 0"
        color="pink"
        class="ma-2 white--text"
        x-large
        @click="report('1')"
      >
        Report Turnstile
        <v-icon right dark> mdi-file-chart </v-icon>
      </v-btn>
      <v-btn
        v-if="reportMenuCarpark.length > 0"
        color="sky blue"
        class="ma-2 white--text"
        x-large
        @click="report('2')"
      >
        Report Carpark
        <v-icon right dark> mdi-file-chart </v-icon>
      </v-btn> -->

      <v-switch color="black" v-model="disable3d" inset></v-switch
      ><span style="padding-top: 1.1rem; color: white; font-size: large"
        >3D</span
      >
    </v-row>
    <div v-if="cards.length > 0">
      <carousel-3d
        :controls-visible="true"
        :clickable="false"
        :disable3d="!disable3d"
        :space="!disable3d ? 356 : 250"
        :width="340"
        :height="480"
        :border="1"
        style="border-radius: 10px"
      >
        <slide v-for="(card, i) in cards" :index="i" :key="card.description">
          <figure
            class="zoom"
            style="
              cursor: pointer;
              background-color: aliceblue;
              border-radius: 30px;
            "
          >
            <img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="480px"
              @click="
                routerPage(
                  card.groupID == 5 || card.groupID == 6
                    ? card.description
                    : card.groupName
                )
              "
            />
            <figcaption>
              {{
                card.groupID == 5 || card.groupID == 6
                  ? card.description
                  : card.groupName
              }}
            </figcaption>
          </figure>
        </slide>
      </carousel-3d>
    </div>
    <!-- <v-row style="display: flex; justify-content: center">

     

      <v-slide-group v-model="model" class="pa-4" center-active>
        <v-slide-item
          v-for="card in cards"
          :key="card.groupName"
          v-slot="{ active, toggle }"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :color="active ? 'grey lighten-1' : 'grey lighten-1'"
              class="ma-4 zoom"
              width="330"
              height="450"
              :elevation="hover ? 8 : 2"
              @click="
                routerPage(
                  card.groupID == 5 || card.groupID == 6
                    ? card.description
                    : card.groupName
                )
              "
            >
              <v-img
                :src="card.src"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="450px"
              >
                <v-card-title
                  class="justify-center"
                  style="font-size: 22px"
                  v-text="
                    card.groupID == 5 || card.groupID == 6
                      ? card.description
                      : card.groupName
                  "
                ></v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-slide-item>
      </v-slide-group>
    </v-row> -->
    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import enurl from "@/api/environment";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { eventBus } from "../main";

export default {
  name: "Main",
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      AlertDialog: false,
      disable3d: true,
      TypeAlert: null,
      MessageAlert: "",
      reportImg: require("@/assets/access.jpg"),
      reportMenu: [],
      reportMenuCarpark: [],
      MenuPermission: [],
      cards: [],
      model: null,
      cardsData: [],
    };
  },
  async mounted() {
    let that = this;
    that.getPermisionAppByRoleID();
    that.getDataMenuPermission();
    that.disable3d = this.$cookies.get("3D");
    // console.log(that.disable3d);
  },
  computed: {},
  watch: {
    disable3d() {
      const d = new Date();
      d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000); //7days
      let expires = "expires=" + d.toUTCString();
      if (this.disable3d == null || this.disable3d == 'true' || this.disable3d == true) {
        document.cookie = "3D" + "=" + true + ";" + expires + ";path=/";
        // console.log(this.disable3d);
        this.disable3d = true;
      } else {
        document.cookie = "3D" + "=" + false + ";" + expires + ";path=/";
        // console.log(this.disable3d);
        this.disable3d = false;
      }
    },
  },
  methods: {
    getPermisionAppByRoleID() {
      let self = this;
      axios
        .get(`${self.url}Role/getPermisionAppByRoleID`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.PermisionAppByRole = response.data.data;
            // console.log(self.PermisionAppByRole);
            localStorage.setItem(
              "Permision",
              JSON.stringify(self.PermisionAppByRole)
            );
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
        });
    },
    getDataMenuPermission() {
      let that = this;
      that.LoadingDialog = true;
      axios
        .get(`${that.url}Permission/getDataMenuPermission`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.cardsData = response.data.data;

            // if (
            //   that.cardsData.indexOf(
            //     that.cardsData.find((x) => x.groupID == 4)
            //   ) != -1
            // ) {
            //   var Index0 = that.cardsData.indexOf(
            //     that.cardsData.find((x) => x.groupID == 4)
            //   );
            //   that.reportMenu.push(that.cardsData.find((x) => x.groupID == 4));
            //   that.cardsData.splice(Index0, 1);
            // }

            // if (
            //   that.cardsData.indexOf(
            //     that.cardsData.find((x) => x.groupID == 5)
            //   ) != -1
            // ) {
            //   var Index = that.cardsData.indexOf(
            //     that.cardsData.find((x) => x.groupID == 5)
            //   );
            //   that.reportMenu.push(that.cardsData.find((x) => x.groupID == 5));
            //   that.cardsData.splice(Index, 1);
            // }

            // if (
            //   that.cardsData.indexOf(
            //     that.cardsData.find((x) => x.groupID == 6)
            //   ) != -1
            // ) {
            //   var Index2 = that.cardsData.indexOf(
            //     that.cardsData.find((x) => x.groupID == 6)
            //   );
            //   that.reportMenuCarpark.push(
            //     that.cardsData.find((x) => x.groupID == 6)
            //   );
            //   that.cardsData.splice(Index2, 1);
            // }

            that.cards = that.cardsData;
            that.cards.forEach((element) => {
              if (element.groupID == 1) {
                element.src = require("@/assets/accessdoor.jpg");
                element.flex = 3;
              }
              if (element.groupID == 2) {
                element.src = require("@/assets/car-park.jpg");
                element.flex = 3;
              }
              if (element.groupID == 3) {
                element.src = require("@/assets/VisitorManagementBG.jpg");
                element.flex = 3;
              }
              if (element.groupID == 4) {
                element.src = require("@/assets/settings.jpg");
                element.flex = 3;
              }
              if (element.groupID == 5) {
                element.src = require("@/assets/turnstile_0.jpg");
                element.flex = 3;
              }
              if (element.groupID == 6) {
                element.src = require("@/assets/parking_0.jpg");
                element.flex = 3;
              }
              if (element.groupID == 7) {
                element.src = require("@/assets/condo.jpg");
                element.flex = 3;
              }
              if (element.groupID == 8) {
                element.src = require("@/assets/FDCentral.jpg");
                element.flex = 3;
              }
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          eventBus.$emit("logout");
        });
    },
    // report(page) {
    //   if (page == "1") {
    //     window.open("http://10.144.52.130/TurnstileReports.aspx", "_blank");
    //   } else {
    //     window.open("http://10.144.52.130/ReportMenu.aspx", "_blank");
    //   }
    // },
    routerPage(page) {
      let that = this;
      localStorage.setItem("FirstPage", "true");
      if (page == "Access Control") {
        localStorage.setItem("GroupId", 1);
        that.$router.push("/Dashboard");
        localStorage.setItem("page", "Dashboard");
      } else if (page == "Carpark Management") {
        localStorage.setItem("GroupId", 2);
        that.$router.push("/DashboardCarpark");
        localStorage.setItem("page", "Dashboard Carpark");
      } else if (page == "Visitor Management") {
        localStorage.setItem("GroupId", 3);
        that.$router.push("/Blank");
        localStorage.setItem("page", "Blank");
      } else if (page == "Setting") {
        localStorage.setItem("GroupId", 4);
        that.$router.push("/Blank");
        localStorage.setItem("page", "Blank");
      } else if (page == "Report Turnstile") {
        window.open("http://10.144.52.130/TurnstileReports.aspx", "_blank");
      } else if (page == "Report Carpark") {
        window.open("http://10.144.52.130/ReportMenu.aspx", "_blank");
      } else if (page == "Residence") {
        localStorage.setItem("GroupId", 7);
        that.$router.push("/Blank");
        localStorage.setItem("page", "Blank");
      }
       else if (page == "Fineday Central") {
        localStorage.setItem("GroupId", 8);
        that.$router.push("/Blank");
        localStorage.setItem("page", "Blank");
      }
    },
  },
};
</script>
<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.centered-input-header >>> input {
  text-align: center;
  color: black;
}
.TitleCSS {
  font-size: 18px;
  font-weight: 300;
  color: black;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: local("English"), url(../assets/Font/Kanit-Regular.ttf);
}
* >>> .v-data-table-header {
  background-color: #333333 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.zoom {
  z-index: 99;
  transition: transform 0.3s; /* Animation */
}

.zoom:hover {
  transform: scale(
    1.05
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.carousel-3d-container figure {
  margin: 0;
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 20px;
  min-width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.carousel-3d-slide {
  border-radius: 10px;
  border-color: grey;
}
.custom-red .v-input--selection-controls__input div {
  color: #6a1b9a;
}
</style>
