<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-img src="@/assets/beach.jpg" width="100%" height="100%" />
      </v-col>
      <v-col cols="12" md="4">
        <v-row style="min-height: 100vh" justify="center" align="center">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2"></v-col>
              <v-col cols="12" md="8">
                <v-img
                  style="filter: invert(1)"
                  :src="PathLogo"
                  width="270"
                  height="70"
                  class="mx-auto"
                ></v-img>
                <br />
                <br />
                <div class="mt-5">
                  <v-row>
                    <v-text-field
                      placeholder="Username"
                      name="login"
                      type="text"
                      outlined
                      v-model="username"
                      @keyup.enter="Login"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field
                      outlined
                      placeholder="Password"
                      type="password"
                      id="password"
                      @keyup.enter="Login"
                      v-model="password"
                    />
                    <span>
                      <v-icon
                        v-show="flagPassword == true"
                        class="mt-4"
                        style="margin-left: -40px"
                        @click="TogglePassword()"
                        >mdi-eye-off</v-icon
                      >
                    </span>
                    <span>
                      <v-icon
                        v-show="flagPassword == false"
                        class="mt-4"
                        style="margin-left: -40px"
                        @click="TogglePassword()"
                        >mdi-eye</v-icon
                      >
                    </span>
                  </v-row>
                  <v-row>
                    <v-btn
                      block
                      color="#9AC5F4"
                      style="color: black"
                      @click="Login()"
                      class="rounded-lg"
                      x-large
                      >Login</v-btn
                    >
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="2"></v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-img
      src="@/assets/background-2.jpg"
      width="100%"
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh' }
          : { height: '100vh' },
      ]"
    >
      <div>
        <v-container>
          <v-row style="min-height: 90vh" justify="center" align="center">
            <v-card
              width="400"
              :class="$vuetify.breakpoint.xs ? 'rounded-0' : 'rounded-lg'"
            >
              <v-card-text style="width: auto">
                <v-tabs class="d-none" v-model="model"> </v-tabs>
                <v-tabs-items v-model="model">
                  <v-tab-item :value="`tab-1`">
                    <v-row style="margin-bottom: -50px">
                      <v-col cols="12" md="1"></v-col>
                      <v-col cols="12" md="10">
                        <v-text-field
                          placeholder="Username"
                          name="login"
                          type="text"
                          outlined
                          v-model="username"
                          @keyup.enter="Login"
                        />
                      </v-col>
                      <v-col cols="12" md="1"></v-col>
                    </v-row>

                    <v-row style="margin-bottom: -50px">
                      <v-col cols="12" md="1"></v-col>
                      <v-col cols="12" md="10">
                        <v-text-field
                          outlined
                          placeholder="Password"
                          type="password"
                          id="password"
                          @keyup.enter="Login"
                          v-model="password"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="1"
                        class="mt-4"
                        v-if="$vuetify.breakpoint.width >= 1024"
                      >
                        <v-icon
                          v-show="flagPassword == true"
                          style="margin-left: -60px"
                          @click="TogglePassword()"
                          >mdi-eye-off</v-icon
                        >
                        <v-icon
                          v-show="flagPassword == false"
                          style="margin-left: -60px"
                          @click="TogglePassword()"
                          >mdi-eye</v-icon
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="1"></v-col>
                      <v-col cols="12" md="10">
                        <v-btn
                          block
                          color="#9AC5F4"
                          style="color: black"
                          @click="Login()"
                          class="rounded-lg"
                          x-large
                          >Login</v-btn
                        >
                      </v-col>
                      <v-col cols="12" md="1"></v-col>
                    </v-row>
                    <br />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-row>
        </v-container>
      </div>
    </v-img> -->
    <v-footer
      color="#1d2939"
      :absolute="$vuetify.breakpoint.height < 786 ? true : true"
      class="white--text text-caption footer"
    >
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2">
              <v-img
                src="@/assets/logofs.svg"
                width="80"
                class="mx-auto mt-1"
              ></v-img>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="8">
              <div class="d-flex mt-2">
                <span style="font-size: small; margin-bottom: 5px; color: white"
                  >Copyright © 2024 Forward System Co. Ltd.</span
                >
              </div>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="4" md="4" lg="1" align="right">
              <div style="font-size: small; color: white">
                <v-img
                  src="@/assets/iconFB.svg"
                  width="35"
                  class="ml-10 mt-1"
                ></v-img>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="1" align="right">
              <div style="font-size: small; color: white">
                <v-img
                  src="@/assets/iconLine.svg"
                  width="35"
                  class="ml-10 mt-1"
                ></v-img>
              </div>
            </v-col> -->
            <!-- <v-col cols="12" xs="12" sm="4" md="4" lg="2">
              <div style="font-size: small; color: white">Contact</div>
              <div style="font-size: small; color: white">
                TEL : 0-2643-7222
              </div>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2"> </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>

    <!-- <v-dialog v-model="LoadingDialog"  persistent persistent width="0.01">
      <v-card>
        <v-card-text>
          <v-row>
            <LoadingComponent class="centered"></LoadingComponent>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
// import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import LoadingComponent from "@/components/Loading";
export default {
  name: "Login",
  components: {
    // LoadingComponent,
  },
  data() {
    return {
      model: "tab-1",
      url: enurl.apiUrl,
      LoadingDialog: false,
      flagPassword: false,
      // username: "admin",
      // password: "fscp_1",
      username: "",
      password: "",
      // PathLogo: require("@/assets/logo-FS.svg"),
      PathLogo: require("@/assets/LogoFinedayFullname.png"),
      expiryDate: "",
    };
  },

  mounted: function () {
    let self = this;
    var isLoggedIn = this.$cookies.get("isLoggedIn");
    localStorage.setItem("isLoggedIn", isLoggedIn);
    // var isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn == "true") {
      let token = this.$cookies.get("Token");

      localStorage.setItem("Token", token);
      // let token = localStorage.getItem("Token");
      if (token != null) {
        self.$store
          .dispatch("CheckToken", { token })
          .then(function () {
            // self.$router.push("/ManageMember");
            self.$router.push("/Main");
          })
          .catch(function (error) {
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
            // console.log(error);
          });
      }
    }
    // localStorage.clear();
  },

  methods: {
    Login: function () {
      let self = this;
      if (!self.username || !self.password) {
        alert("กรุณากรอกข้อมูลให้ครบถ้วน !");
      } else {
        self.LoadingDialog = true;
        let temp = {
          Username: self.username,
          Password: self.password,
        };
        self.$store
          .dispatch("LoginUser", temp)
          .then(function (response) {
            //console.log(response);
            if (response.data.status == 0) {
              self.LoadingDialog = false;
              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("Username", self.username);
              self.$router.push("/Main");
            }
            if (response.data.status == 1) {
              Swal.fire({
                icon: "warning",
                title: "Warning...",
                width: 900,
                text: response.data.message,
              });
              self.LoadingDialog = false;
            }
          })
          .catch(function (error) {
            self.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    TogglePassword() {
      let self = this;
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        self.flagPassword = false;
      } else {
        x.type = "password";
        self.flagPassword = true;
      }
    },
  },
};
</script>
<style scoped>
.login {
  width: 14rem;
}

.form {
  min-width: 50%;
}

.img-intersection {
  width: 100%;
  height: 100vh;
  max-width: 100%;
}

@media only screen and (max-width: 959px) {
  .logocss {
    display: none;
  }
  .rescss {
    margin: 1rem;
    width: 100% !important;
  }
}
.centered-input-header >>> input {
  text-align: center;
  color: black;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}
.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}
.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
