<template>
  <v-app>
    <NavBar v-if="$route.path != '/Login' && $route.path != '/Main' && $route.path != '/Accept'" />
    <NavBarMain v-if="$route.path == '/Main' " />
    <v-main v-if="$route.path != '/Main'">
      <div>
        <router-view></router-view>
      </div>
    </v-main>
    <v-main
      id="grad2"
      v-if="$route.path == '/Main'"
      style="display: grid; align-content: center"
    >
      <div>
        <!-- <v-img src="@/assets/beach.jpg" width="100%" height="100%" />    -->
        <router-view></router-view>
      </div>
    </v-main>
    <!-- <v-main
      id="grad1"
      v-if="$route.path == '/Report'"
    >
      <div>
        <router-view></router-view>
      </div>
    </v-main> -->
    <Footer v-if="$route.path != '/Login' && $route.path != '/Accept'" />
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import NavBarMain from "./components/NavBarMain";
import Footer from "./components/Footer";
export default {
  name: "App",

  components: {
    NavBar,
    Footer,
    NavBarMain,
  },
};
</script>

<style>
@font-face {
  font-family: "English";
  src: local("English"), url(./assets/Font/Kanit-Regular.ttf);
}

.v-content__wrap {
  background-color: white;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 959px) {
  .v-content__wrap {
    background-color: white;
    width: 80%;
  }
}

#grad1 {
    height: 200px;
    background: #78A3D4; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(#78A3D4, #FFF); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#78A3D4, #FFF); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#78A3D4, #FFF); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#78A3D4, #FFF); /* Standard syntax (must be last) */
}
#grad2 {
    height: auto;
    
    /* background: #78A3D4;
    background: -webkit-linear-gradient(#78A3D4, #FFF); 
    background: -o-linear-gradient(#78A3D4, #FFF); 
    background: -moz-linear-gradient(#78A3D4, #FFF);  */ 
    background: linear-gradient( rgba(255, 253, 253, 0.8), rgba(255, 253, 253, 0.2) ), url(./assets/mainmenu_bg.jpg);
    background-repeat: no-repeat;  
    background-position: center;
    background-size: cover;
    /* background-image: url(./assets/mainmenu_bg.jpg); */
    /* object-fit: cover; */
}

.clouds-1 {
  background-image: url("https://cdn.discordapp.com/attachments/990641651175264306/1026619079949291570/clouds_233.png");
  animation: clouds-loop-1 20s infinite linear;
}

* {
  font-family: "English";
}

#app {
  color: #444444;
}
::-webkit-scrollbar {
  display: none;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 200px;
  height: 200px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
