<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Rate Template Management
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="4" class="pt-5">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    v-on:keyup.enter="SearchRate(search)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getRateList()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="permissionsPage.create"
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenRateDialog('create', null)"
                    >
                      Create
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="RateList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.templateNameTH }}</td>
                    <td class="header-table-css">{{ item.templateNameEng }}</td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 100px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center; min-width: 150px;">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenRateDialog('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveTemplateByTemplateID(item.templateID)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="RateDialog" persistent max-width="900px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="EditStatus" class="text-h5">Edit Rate Template</span>
          <span v-else class="text-h5">Map Rate Template</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="5" md="5" lg="5" class="pt-5">
                <v-text-field
                  v-model="nameTemplateTH"
                  label="Template Name TH"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5" lg="5" class="pt-5">
                <v-text-field
                  v-model="nameTemplateEng"
                  label="Template Name Eng"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-switch
                  v-model="Status"
                  hide-details
                  inset
                  :label="Status ? 'Active' : 'InActive'"
                ></v-switch>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-select
                  :items="ProfileList"
                  label="Rate Profile"
                  v-model="profileIDFirst"
                  item-text="profileNameTH"
                  item-value="profileID"
                  required
                  clearable
                  outlined
                  dense
                  @change="updateNameTemplate()"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-select
                  :items="Conjunction"
                  label="Conjunction"
                  v-model="conjunctionID"
                  item-text="conjunctionNameTH"
                  item-value="conjunctionID"
                  required
                  clearable
                  outlined
                  dense
                  @change="updateNameTemplate()"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <v-select
                  :items="ProfileList"
                  label="Rate Profile"
                  v-model="profileIDSecond"
                  item-text="profileNameTH"
                  item-value="profileID"
                  required
                  clearable
                  outlined
                  dense
                  @change="updateNameTemplate()"
                ></v-select>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" sm="12" md="12" style="text-align: end">
                <v-btn
                  class="ma-2"
                  color="primary"
                  width="100"
                  @click="AddTemplateList()"
                >
                  Add
                  <v-icon right>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headersTemplate"
                  :items="TemplateList"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="header-table-css">
                        {{ ConvertTemplate(item.RateProfile1) }}
                      </td>
                      <td class="header-table-css">
                        {{ ConvertConjunction(item.Conjunction) }}
                      </td>
                      <td class="header-table-css">
                        {{ ConvertTemplate(item.RateProfile2) }}
                      </td>
                      <td style="text-align: center">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="red"
                          @click="DeleteTemplateData(item)"
                        >
                          <v-icon dark>mdi-trash-can-outline </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addRateTemplate()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addRateTemplate()"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CloseRateDialog()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="AddTemplateDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <span>Rate Template</span>
          <v-spacer></v-spacer>
          <v-row class="mt-3">
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-select
                :items="ProfileList"
                label="Rate Profile"
                v-model="profileIDFirstTemp"
                item-text="profileNameTH"
                item-value="profileID"
                required
                clearable
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-select
                :items="Conjunction"
                label="Conjunction"
                v-model="conjunctionIDTemp"
                item-text="conjunctionNameTH"
                item-value="conjunctionID"
                required
                clearable
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-select
                :items="ProfileList"
                label="Rate Profile"
                v-model="profileIDSecondTemp"
                item-text="profileNameTH"
                item-value="profileID"
                required
                clearable
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" width="100" @click="AddTemplateData()"
            >Add</v-btn
          >
          <v-btn
            color="pink white--text"
            width="100"
            @click="CloseAddTemplateDialog()"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "ManageRateTemplate",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      RateList: [],
      ProfileList: [],
      Conjunction: [],
      search: "",
      activeStatus: null,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      RateDialog: false,
      EditStatus: false,

      // Add Edit
      rateID: 0,
      templateName: "",
      templateNameTH: "",
      templateNameEng: "",

      rateNameTH: "",
      Status: true,

      invoiceStatus: false,
      lumpSumStatus: false,
      freeStatus: false,
      periodStatus: false,
      amount: 0,
      templateTime: 0,

      templateID: 0,
      startPeriod: null,
      endPeriod: null,
      menu2: false,
      modal_startPeriod: false,
      modal_endPeriod: false,

      nameTemplateTH: "",
      nameTemplateEng: "",
      profileIDFirst: 0,
      profileIDSecond: 0,
      conjunctionID: 0,
      TemplateList: [],
      AddTemplateDialog: false,
      profileIDFirstTemp: 0,
      profileIDSecondTemp: 0,
      conjunctionIDTemp: 0,
      IndexTemplate: 0,
      permissionsPage: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Template Name TH",
          align: "left",
          sortable: false,
        },
        {
          text: "Template Name Eng",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersTemplate() {
      return [
        {
          text: "RateProfile",
          align: "left",
          sortable: false,
        },
        {
          text: "Conjunction",
          align: "left",
          sortable: false,
        },
        {
          text: "RateProfile",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    self.getRateList();
    self.getMasRateProfileList();
    self.getMasConjunction();
  },

  methods: {
    AddTemplateList() {
      let self = this;
      self.AddTemplateDialog = true;
    },

    CloseAddTemplateDialog() {
      let self = this;
      self.AddTemplateDialog = false;
    },

    CloseRateDialog() {
      let self = this;
      self.TemplateList = [];
      self.nameTemplateTH = "";
      self.nameTemplateEng = "";
      self.Status = true;
      self.RateDialog = false;
    },

    AddTemplateData() {
      let self = this;
      self.TemplateList == null ? [] : self.TemplateList;
      let temp = {
        RateProfile1: self.profileIDFirstTemp,
        Conjunction: self.conjunctionIDTemp,
        RateProfile2: self.profileIDSecondTemp,
      };
      self.TemplateList.push(temp);

      (self.profileIDFirstTemp = 0),
        (self.conjunctionIDTemp = 0),
        (self.profileIDSecondTemp = 0);
      self.AddTemplateDialog = false;
    },

    DeleteTemplateData(data) {
      let self = this;
      if (data != null) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            var Index = self.TemplateList.indexOf(data);
            self.TemplateList.splice(Index, 1);
          }
        });
      }
    },

    ConvertTemplate(data) {
      let self = this;
      if (data != 0 && data != null) {
        return self.ProfileList.find((x) => x.profileID == data).profileNameTH;
      }
    },

    ConvertConjunction(data) {
      let self = this;
      // console.log(data);
      if (data != 0 && data != null) {
        return self.Conjunction.find((x) => x.conjunctionID == data)
          .conjunctionNameTH;
      }
    },

    updateNameTemplate() {
      let that = this;
      let profileIDFirstName =
        that.profileIDFirst == 0 || that.profileIDFirst == null
          ? ""
          : that.ProfileList.find((x) => x.profileID == that.profileIDFirst)
              .profileNameTH;
      let profileIDSecondName =
        that.profileIDSecond == 0 || that.profileIDSecond == null
          ? ""
          : that.ProfileList.find((x) => x.profileID == that.profileIDSecond)
              .profileNameTH;
      let conjunctionName =
        that.conjunctionID == 0 || that.conjunctionID == null
          ? ""
          : that.Conjunction.find((x) => x.conjunctionID == that.conjunctionID)
              .conjunctionNameTH;

      let profileIDFirstNameEng =
        that.profileIDFirst == 0 || that.profileIDFirst == null
          ? ""
          : that.ProfileList.find((x) => x.profileID == that.profileIDFirst)
              .profileNameEng;
      let profileIDSecondNameEng =
        that.profileIDSecond == 0 || that.profileIDSecond == null
          ? ""
          : that.ProfileList.find((x) => x.profileID == that.profileIDSecond)
              .profileNameEng;

      let conjunctionNameEng =
        that.conjunctionID == 0 || that.conjunctionID == null
          ? ""
          : that.Conjunction.find((x) => x.conjunctionID == that.conjunctionID)
              .conjunctionNameEng;

      that.nameTemplateTH =
        profileIDFirstName + " " + conjunctionName + " " + profileIDSecondName;

      that.nameTemplateEng =
        profileIDFirstNameEng +
        " " +
        conjunctionNameEng +
        " " +
        profileIDSecondNameEng;
    },

    getRateList() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            // console.log(self.RateList);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getRateTemplateByTemplateID() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        templateID: self.templateID,
      };

      axios
        .post(`${self.url}Rate/getRateTemplateByTemplateID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // self.ProfileList = response.data.data;
            // console.log(response.data.data);
            self.BindingEditData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    getMasRateProfileList() {
      let self = this;
      self.LoadingDialog = true;

      axios
        .get(`${self.url}Rate/getMasRateProfileList`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.ProfileList = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    getMasConjunction() {
      let self = this;
      self.LoadingDialog = true;

      axios
        .get(`${self.url}Rate/getMasConjunction`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.Conjunction = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchRate(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Rate/getMasRateTemplate`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RateList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    checkVakidates() {
      let that = this;
      if (
        that.nameTemplateTH == "" ||
        that.nameTemplateTH == null ||
        that.nameTemplateEng == "" ||
        that.nameTemplateEng == null
        // (that.profileIDFirst == 0 || that.profileIDFirst == null) ||
        // (that.profileIDSecond == 0 || that.profileIDSecond == null) ||
        // (that.conjunctionID == 0 || that.conjunctionID == null)
      ) {
        return true;
      } else {
        return false;
      }
    },

    addRateTemplate() {
      let that = this;
      if (that.checkVakidates()) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      // that.LoadingDialog = true;

      if (!that.EditStatus) {
        let templateData = [];
        if (that.TemplateList.length > 0) {
          for (let i = 0; i < that.TemplateList.length; i++) {
            if (that.TemplateList[i].RateProfile1 != 0) {
              let tempdata = {
                RateProfile: that.TemplateList[i].RateProfile1,
                Conjunction: that.TemplateList[i].Conjunction,
              };
              templateData.push(tempdata);
            }
            if (that.TemplateList[i].RateProfile2 != 0) {
              let tempdata = {
                RateProfile: that.TemplateList[i].RateProfile2,
                Conjunction: that.TemplateList[i].Conjunction,
              };
              templateData.push(tempdata);
            }
          }
        }
        let temp = {
          nameTemplateTH: that.nameTemplateTH,
          nameTemplateEng: that.nameTemplateEng,
          templateList: templateData,
          active: that.Status,
        };
        axios
          .post(`${that.url}Rate/addRateTemplate`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.CloseRateDialog();
            that.getRateList();
            that.RateDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let templateData = [];
        if (that.TemplateList.length > 0) {
          for (let i = 0; i < that.TemplateList.length; i++) {
            if (that.TemplateList[i].RateProfile1 != 0) {
              let tempdata = {
                RateProfile: that.TemplateList[i].RateProfile1,
                Conjunction: that.TemplateList[i].Conjunction,
              };
              templateData.push(tempdata);
            }
            if (that.TemplateList[i].RateProfile2 != 0) {
              let tempdata = {
                RateProfile: that.TemplateList[i].RateProfile2,
                Conjunction: that.TemplateList[i].Conjunction,
              };
              templateData.push(tempdata);
            }
          }
        }
        let temp = {
          templateID: that.templateID,
          nameTemplateTH: that.nameTemplateTH,
          nameTemplateEng: that.nameTemplateEng,
          templateList: templateData,
          active: that.Status,
        };
        axios
          .post(`${that.url}Rate/editRateTemplate`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.CloseRateDialog();
            that.getRateList();
            that.RateDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    GetColor(value) {
      if (value == true) {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    OpenRateDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;
        // self.amount = 0;
        // self.templateName = "";
        // self.templateTime = 0;
        // self.invoiceStatus = false;
        // self.freeStatus = false;
        // self.Status = true;
        // self.periodStatus = false;
        // self.lumpSumStatus = false;
        //
        self.nameTemplateTH = "";
        self.nameTemplateEng = "";
        self.profileIDFirst = 0;
        self.profileIDSecond = 0;
        self.conjunctionID = 0;
        self.Status = true;
        self.RateDialog = true;
      } else {
        // self.BindingEditData(data);
        self.templateID = data.templateID;
        self.getRateTemplateByTemplateID();
      }
    },

    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      self.RateDialog = true;

      self.templateID = data.templateID;
      self.nameTemplateTH = data.nameTemplateTH;
      self.nameTemplateEng = data.nameTemplateEng;

      if (data.templateList.length > 0) {
        for (let i = 0; i < data.templateList.length; i++) {
          let temp = {
            RateProfile1: data.templateList[i].rateProfile1,
            Conjunction: data.templateList[i].conjunction,
            RateProfile2: data.templateList[i].rateProfile2,
          };
          self.TemplateList.push(temp);
        }
      }
      self.Status = data.active;
    },

    inActiveTemplateByTemplateID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        TemplateID: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Template ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Rate/inActiveTemplateByTemplateID`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getRateList();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
