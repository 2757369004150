<template>
  <v-container style="max-width: 1200px">
    <v-row style="display: flex; justify-content: center">
      <v-col col="8" md="8" lg="8" sm="8">
        <v-card elevation="8" style="min-height: 500px"> fsf </v-card>
      </v-col>
      <!-- <v-col v-for="card in cards" :key="card.groupName" :cols="card.flex">
        <v-hover v-slot="{ hover }">
          <v-card
            max-width="300px"
            @click="routerPage(card.groupName)"
            :elevation="hover ? 15 : 2"
          >
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="400px"
            >
            <v-card-title class="justify-center" style="font-size: 22px;" v-text="card.groupName"></v-card-title>
             
            </v-img>

         
          </v-card>
        </v-hover>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import enurl from "@/api/environment";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "SelectMenu",
  components: {},
  data() {
    return {
      url: enurl.apiUrl,
      LoadingDialog: false,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      MenuPermission: [],
      cards: [],
    };
  },
  async mounted() {
    let that = this;
    that.getDataMenuPermission();
  },
  computed: {},
  watch: {},
  methods: {
    getDataMenuPermission() {
      let that = this;
      axios
        .get(`${that.url}Permission/getDataMenuPermission`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.cards = response.data.data;
            that.cards.forEach((element) => {
              if (element.groupID == 1) {
                element.src = require("@/assets/access.jpg");
                element.flex = 3;
              }
              if (element.groupID == 2) {
                element.src = require("@/assets/carpark.jpg");
                element.flex = 3;
              }
              if (element.groupID == 3) {
                element.src = require("@/assets/VisitorManagementBG.jpg");
                element.flex = 3;
              }
              if (element.groupID == 4) {
                element.src = require("@/assets/settings.jpg");
                element.flex = 3;
              }
            });
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
          // eventBus.$emit('logout');
        });
    },
    routerPage(page) {
      let that = this;
      if (page == "Access Control") {
        localStorage.setItem("GroupId", 1);
        that.$router.push("/Dashboard");
        localStorage.setItem("page", "Dashboard");
      } else if (page == "Carpark Management") {
        localStorage.setItem("GroupId", 2);
        that.$router.push("/DashboardCarpark");
        localStorage.setItem("page", "DashboardCarpark");
      } else if (page == "Visitor Management") {
        localStorage.setItem("GroupId", 3);
        that.$router.push("/Dashboard");
        localStorage.setItem("page", "Dashboard");
      } else if (page == "Setting") {
        localStorage.setItem("GroupId", 4);
        that.$router.push("/Dashboard");
        localStorage.setItem("page", "Dashboard");
      }
    },
  },
};
</script>
<style scoped>
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.centered-input-header >>> input {
  text-align: center;
  color: black;
}
.TitleCSS {
  font-size: 18px;
  font-weight: 300;
  color: black;
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: local("English"), url(../../assets/Font/Kanit-Regular.ttf);
}
* >>> .v-data-table-header {
  background-color: #333333 !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
