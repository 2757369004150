<template>
  <div style="padding: 3%;">
    <v-row>
      <v-col cols="12" md="3"></v-col>
      <v-col cols="12" md="3">
        <p>Camera 1</p>
        <video width="540" height="380" autoplay :src="cameraUrl" controls></video>
      </v-col>
      <v-col cols="12" md="6">
        <!-- <p>Camera 2</p>
        <video width="540" height="380" autoplay :src="cameraUrl" controls></video> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import enurl from "@/api/environment";

export default {
  name: "Camera",
  data() {
    return {
      url: enurl.apiUrl,
      rtsp: 'rtsp://192.168.223.35/user=admin_password=tlJwpbo6_channel=1_stream=1.sdp?real_stream',
      cameraUrl: '',
    };
  },
  methods: {
    getCameraStream(){
      let self = this;
      let temp = {
        rtsp_string: self.rtsp
      };
      axios
        .post(`${self.url}Camera/getCameraStream`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.cameraUrl = response.data.data;
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  },
  mounted() {
    this.getCameraStream();
  },
};
</script>