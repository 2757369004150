<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          <v-row>
            <v-col cols="6" md="6" lg="6" style="text-align: start">
              Search Car Information
            </v-col>
            <v-col cols="6" md="6" lg="6" style="text-align: end">
            </v-col>
          </v-row>
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" class="pt-5">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-row>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-text-field
                            label="Card Number"
                            append-icon="mdi-database-search-outline"
                            v-model="logEntry"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-text-field
                            label="Licensee Plate"
                            append-icon="mdi-database-search-outline"
                            v-model="carNo"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-select
                            :items="['Car', 'Motorcycle']"
                            label="Vehicle Type"
                            v-model="vehicleType"
                            required
                            clearable
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-text-field
                            label="Ticket No"
                            append-icon="mdi-database-search-outline"
                            v-model="ticketNo"
                            clearable
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <!-- <v-text-field
                            label="Tenant"
                            append-icon="mdi-database-search-outline"
                            v-model="ticketNo"
                            clearable
                            outlined
                            dense
                          ></v-text-field> -->
                          <v-autocomplete
                            :items="tenantArray"
                            item-value="tenantID"
                            item-text="tenantName"
                            label="Tenant"
                            v-model="SearchTenant"
                            required
                            clearable
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-select
                            :items="['ยังอยู่ในระบบ', 'ออกจากระบบ']"
                            label="Exit Status"
                            v-model="statusLog"
                            required
                            clearable
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-menu
                            v-model="menuStartDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="entryDate"
                                label="Start Date"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="entryDate"
                              @change="menuStartDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-menu
                            v-model="menuEndDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="exitDate"
                                label="End Date"
                                append-icon="mdi-calendar"
                                outlined
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="exitDate"
                              @change="menuEndDate = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-autocomplete
                            :items="DropdownTerminalIn"
                            item-value="terminalID"
                            item-text="terminalName"
                            label="Entry Gate"
                            v-model="terminalIDIn"
                            required
                            clearable
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                          <v-autocomplete
                            :items="DropdownTerminalOut"
                            item-value="terminalID"
                            item-text="terminalName"
                            label="Exit Gate"
                            v-model="terminalIDOut"
                            required
                            clearable
                            outlined
                            dense
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          lg="4"
                          class="mt-1"
                          style="text-align: end"
                        >
                          <v-btn
                            :color="'blue'"
                            class="ma-2 white--text"
                            @click="getDataTransactionCarpark()"
                          >
                            Search

                            <v-icon right> mdi-magnify </v-icon>
                          </v-btn>
                          <vue-excel-xlsx
                            :data="dataExport"
                            :columns="columns"
                            :file-name="'Transaction Carpark Log ' + Date.now()"
                            :file-type="'xlsx'"
                            :sheet-name="'Transaction Carpark'"
                          >
                            <v-btn color="orange" class="ma-2 white--text">
                              Export Excel
                              <v-icon right>mdi-file-excel-outline</v-icon>
                            </v-btn>
                          </vue-excel-xlsx>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding-left: 2%; margin-top: 2%; margin-bottom: 2%; padding-right: 2%;"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="DataList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-center-css">
                      {{ no_run + (index + 1) }}
                    </td>
                    <td class="header-table-center-css">{{ item.logEntry }}</td>
                    <td class="header-table-center-css">{{ item.carNo }}</td>
                    <td class="header-table-center-css">
                      {{ item.tenantName == null ? "" : item.tenantName }}
                    </td>
                    <td class="header-table-center-css">
                      {{ ChangeFormatDate(item.entryDate) }}
                    </td>
                    <td class="header-table-center-css">
                      {{ ChangeFormatDate(item.exitDate) }}
                    </td>
                    <td class="header-table-center-css">
                      {{ item.vehicleType == 0 ? "Car" : "Motorcycle" }}
                    </td>
                    <td class="header-table-center-css">
                      {{ item.ticketNo }}
                    </td>
                    <td class="header-table-center-css">
                      {{ ConvertTerminalID(item.terminalEntry) }}
                    </td>
                    <td class="header-table-center-css">
                      {{ ConvertTerminalID(item.terminalExit) }}
                    </td>

                    <td style="text-align: center; min-width: 150px">
                      <v-btn
                        v-if="permissionsPage.read"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="blue"
                        @click="getDataTransactionCarparkByLogID(item.logEntry)"
                      >
                        <v-icon dark> mdi mdi-eye </v-icon>
                      </v-btn>

                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        small
                        color="red"
                        :disabled="item.exitStatus == 1"
                        @click="OpenDialogForce(item.logEntry)"
                      >
                        <v-icon style="color: white">
                          mdi-location-exit
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog
      v-model="TransactionCarparkDialog"
      persistent
      max-width="1100px"
      scrollable
    >
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Carpark Transaction</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row style="margin-top: 1%">
              <v-col cols="12" md="6" sm="6">
                <v-card class="mt-3 mx-auto" height="150">
                  <v-sheet
                    class="v-sheet--offset mx-auto"
                    color="green"
                    elevation="12"
                    max-width="calc(100% - 32px)"
                  >
                    <v-card-title
                      style="
                        color: white;
                        display: flex;
                        font-size: 18px;
                        justify-content: space-between;
                      "
                    >
                      <strong>License Plate</strong>Vehicle Type :
                      <div v-if="DataListCarparkID.vehicleType == 'Motorcycle'">
                        <v-icon style="color: white; font-size: 30px"
                          >mdi-motorbike</v-icon
                        >
                        <span class="ml-3">Motorcycle</span>
                      </div>
                      <div v-if="DataListCarparkID.vehicleType == 'Car'">
                        <v-icon style="color: white; font-size: 30px"
                          >mdi-car-estate</v-icon
                        >
                        <span class="ml-3">Car</span>
                      </div>
                    </v-card-title>
                  </v-sheet>

                  <v-card-subtitle>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="text-align: center; font-size: 60px"
                      >
                        <strong> {{ DataListCarparkID.carNo }}</strong>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-card class="mt-3 mx-auto" height="150">
                  <v-sheet
                    class="v-sheet--offset mx-auto"
                    color="blue"
                    elevation="12"
                    max-width="calc(100% - 32px)"
                    height="70"
                  >
                    <v-card-title
                      style="
                        color: white;
                        display: flex;
                        font-size: 18px;
                        justify-content: space-between;
                      "
                    >
                      <strong>Transaction Entry Log</strong>
                      Status :
                      {{
                        DataListCarparkID.exitStatus == 0
                          ? "Still Remain"
                          : "Exit"
                      }}
                    </v-card-title>
                  </v-sheet>

                  <v-card-subtitle>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        style="text-align: center; font-size: 30px"
                      >
                        <strong> {{ DataListCarparkID.logID }}</strong>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card class="mt-3 mx-auto" elevation="10">
                  <v-card-title class="text-h6">
                    <strong>Parking Information</strong>
                  </v-card-title>
                  <v-card-subtitle>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <span style="font-size: 18px; color: black"
                          >Entry Log: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.logID
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black"
                          >Exit Log: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.logIDExit
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black"
                          >Ticket No: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.ticketNo
                          }}</span></span
                        ><br />
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <span style="font-size: 18px; color: black">
                          Entry Date: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.entryDate
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black">
                          Exit Date: &nbsp;<span style="color: #666666">{{
                            ChangeFormatDate(DataListCarparkID.exitDate)
                          }}</span></span
                        ><br />
                        <span style="font-size: 18px; color: black">
                          Parking Time: &nbsp;<span style="color: #666666">{{
                            DataListCarparkID.parkTime
                          }}</span></span
                        >
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="ImageList.length != 0">
                <v-card class="mt-3 mx-auto" elevation="10">
                  <v-card-title class="text-h6">
                    <strong>Parking Images</strong>
                  </v-card-title>
                  <v-card-subtitle>
                    <v-row
                      style="display: flex"
                      v-for="item in ImageList"
                      :key="item.id"
                    >
                      <v-col cols="12" sm="12" md="12">
                        <div style="font-size: large">
                          Images {{ item.logType }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        style="text-align: left; color: black; cursor: pointer"
                        v-if="item.driverImage != ''"
                      >
                        <v-img
                          :src="item.driverImage"
                          @click="OpenImageCarpark(item.driverImage)"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        style="text-align: left; color: black; cursor: pointer"
                        v-if="item.licensePlateImage != ''"
                      >
                        <v-img
                          :src="item.licensePlateImage"
                          @click="OpenImageCarpark(item.licensePlateImage)"
                        ></v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        style="text-align: left; color: black; cursor: pointer"
                        v-if="item.lpR_Image != ''"
                      >
                        <v-img
                          :src="item.lpR_Image"
                          @click="OpenImageCarpark(item.lpR_Image)"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
              <v-row style="text-align: -webkit-center"> </v-row>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: right; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="permissionsPage.delete"
                variant="outlined"
                color="primary"
                style="min-width: 150px; color: white; margin-right: 20px"
                append-icon="mdi-close-circle-outline"
                @click="OpenQrImage(DataListCarparkID.logID)"
              >
                Reprint
              </v-btn>
              <v-btn
                variant="outlined"
                color="red"
                style="min-width: 150px; color: white"
                append-icon="mdi-close-circle-outline"
                @click="TransactionCarparkDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ShowImageDialog" persistent width="1100">
      <v-card>
        <v-card-text>
          <v-col cols="12" md="12" sm="12">
            <v-img :src="ShowImageText"></v-img>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: center; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CloseImageCarpark()"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ShowReprintImageDialog" persistent width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              md="6"
              sm="6"
              style="display: flex; justify-content: center; margin-top: 20px"
            >
              <v-card
                id="capture"
                elevation="7"
                height="750"
                style="
                  background-color: rgb(255, 255, 255) !important;
                  height: 700px;
                  page-break-after: always;
                  width: 350px;
                  padding: 0;
                "
              >
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="background-color: #fff"
                >
                  <v-img
                    height="38"
                    width="400"
                    src="@/assets/logo-01.png"
                  ></v-img>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="
                    text-align: center;
                    padding: 0;
                    display: grid;
                    justify-content: space-evenly;
                    background-color: #fff;
                  "
                >
                  <qr-code
                    style="margin: 12px"
                    :size="180"
                    :text="qrImage"
                    error-level="M"
                  ></qr-code>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="background-color: #fff"
                >
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="10"
                      md="10"
                      sm="10"
                      lg="10"
                      style="
                        text-align: center;
                        padding: 0;
                        background-color: #fff;
                      "
                    >
                      <label style="font-size: 16px">LogId : {{ logId }}</label>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="background-color: #fff"
                >
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="10"
                      md="10"
                      sm="10"
                      lg="10"
                      style="
                        padding: 0;
                        background-color: #fff;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        color: black;
                      "
                    >
                      <label style="font-size: 16px"
                        >Ticket : {{ ticket }}</label
                      >
                      <label style="font-size: 16px"
                        >License : {{ license }}</label
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="background-color: #fff"
                >
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="10"
                      md="10"
                      sm="10"
                      lg="10"
                      style="
                        padding: 0;
                        background-color: #fff;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        color: black;
                      "
                    >
                      <label style="font-size: 16px">Date : {{ date }}</label>
                      <label style="font-size: 16px">Time : {{ time }}</label>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="
                    background-color: #fff;
                    display: grid;
                    justify-content: space-evenly;
                    line-height: 1.3;
                    padding-top: 20px;
                  "
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-center"
                      md="12"
                      sm="12"
                      lg="12"
                      style="
                        padding: 0;
                        background-color: #fff;
                        font-weight: 600;
                        color: black;
                        text-align: center;
                      "
                    >
                      <hr
                        style="
                          width: auto;
                          margin-left: 30px !important;
                          margin-right: 30px !important;
                          font-weight: 400;
                        "
                      />
                      <label style="font-size: 13px">{{
                        "Please complete parking fees before exit"
                      }}</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      lg="12"
                      style="
                        padding: 0;
                        background-color: #fff;
                        text-align: center;
                        color: black;
                      "
                    >
                      <label style="font-size: 11px">{{
                        "โปรดตรวจสอบด่าบริการก่อนออกจากอาคารจอครถ"
                      }}</label>
                      <hr
                        style="
                          width: auto;
                          margin-left: 30px !important;
                          margin-right: 30px !important;
                          font-weight: 400;
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="background-color: #fff; padding-top: 15px"
                >
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="10"
                      md="10"
                      sm="10"
                      lg="10"
                      style="padding: 0; background-color: #fff"
                    >
                      <v-card-text
                        v-html="dialogText"
                        style="
                          font-size: 10px;
                          padding: 0;
                          line-height: 1.1;
                          color: black;
                        "
                      ></v-card-text>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  lg="12"
                  style="background-color: #fff; padding-top: 130px"
                >
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col
                      cols="10"
                      md="10"
                      sm="10"
                      lg="10"
                      style="padding: 0; background-color: #fff"
                    >
                      <label style="font-size: 10px">{{
                        "*100THB for re-printing parking ticket"
                      }}</label>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-col>
              </v-card>
            </v-col>
            <v-col
              cols="6"
              md="6"
              sm="6"
              style="display: flex; justify-content: center"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    text-align: center;
                  "
                >
                  <v-btn
                    variant="outlined"
                    color="success"
                    class="mt-5"
                    style="margin: 10px; width: 150px; height: 150px"
                    @click="print()"
                  >
                    <v-icon> mdi-printer </v-icon>
                    Print
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    text-align: center;
                  "
                >
                  <v-btn
                    variant="outlined"
                    color="silver"
                    class="mt-5"
                    style="margin: 10px; width: 150px; height: 150px"
                    @click="ShowReprintImageDialog = false"
                  >
                    <v-icon> mdi-close-circle-outline </v-icon>
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="DialogReprint" persistent scrollable max-width="1200px">
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Reprint Transaction</span>
        </v-card-title>
        <v-card-text>
          <ReprintComponent />
        </v-card-text>

        <v-card-actions style="background-color: #f9f9f9">
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 10px; margin-top: 10px"
            >
              <v-spacer></v-spacer>

              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px; background-color: white"
                append-icon="mdi-close-circle-outline"
                @click="DialogReprint = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReprintComponent from "@/components/ReprintComponent";
import html2canvas from "html2canvas";
import { eventBus } from "../../main";

export default {
  name: "SearchCarInformation",
  components: {
    ReprintComponent,
  },
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      urlCarpark: enurl.apiCarparkUrl,
      LoadingDialog: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      DataList: [],
      DataListCarparkID: [],
      DataListImgID: [],
      ConfigTerminal: [],
      DropdownConfigTerminal: [],
      DropdownTerminalIn: [],
      terminalIDIn: null,
      terminalIDOut: null,
      DropdownTerminalOut: [],
      ImageList: [],

      DriverImg: "",
      LicensePlateImg: "",
      LPRImg: "",

      search: "",
      activeStatus: null,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      TransactionCarparkDialog: false,
      EditStatus: false,
      ShowImageDialog: false,
      ShowReprintImageDialog: false,
      DialogReprint: false,
      ShowImageText: "",
      // Add Edit

      Status: true,

      searchBy: "",
      listType: "",
      text: "",
      vmsKiosk: "",
      stamp: "",

      logEntry: "",
      carNo: "",
      entryDate: "",
      exitDate: "",
      vehicleType: "",
      ticketNo: "",
      terminalEntry: "",
      statusLog: "",
      password: "",

      permissionsPage: [],
      ExportButtonRolePermission: null,
      tenantArray: [],
      SearchTenant: "",
      dataExport: [],

      logId: "",
      qrImage: "",
      ticket: "",
      license: "",
      date: "",
      time: "",

      dialogText:
        "Parking Payment Options<br/>1. Mobile Payment Scan QR code through mobile camera<br/>or One Bangkok application<br/>2. Kiosk Payment Scan QR cade at parking payment kiosk located at retail area<br/><br/>วิธีชำระค่าบริการ<br/>1. Mobile Payment สแกนQR codeด้านบนผ่านโทรศัพท์มือถือ หรือ<br/>One Bangkok แอพพลิเคชั่น<br/>2. Kiosk Payment นำบัตรจอดรถแสกนที่ตู้อัตโนมัติภายในศูนย์ฯ",

      columns: [
        {
          label: "",
          field: "LogEntry",
        },
        {
          label: "",
          field: "LicensePlate",
        },
        {
          label: "",
          field: "Tenant",
        },
        {
          label: "",
          field: "DatetimeIn",
        },
        {
          label: "",
          field: "DatetimeOut",
        },
        {
          label: "",
          field: "VehicleType",
        },
        {
          label: "",
          field: "TicketNo",
        },
        {
          label: "",
          field: "TerminalIn",
        },
        {
          label: "",
          field: "TerminalOut",
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "Transaction Entry Log",
          align: "center",
          sortable: false,
        },
        {
          text: "Licensee Plate",
          align: "center",
          sortable: false,
        },
        {
          text: "Tenant",
          align: "center",
          sortable: false,
        },
        {
          text: "Entry date",
          align: "center",
          sortable: false,
        },
        {
          text: "Exit date",
          align: "center",
          sortable: false,
        },
        {
          text: "Vehicle Type",
          align: "center",
          sortable: false,
        },
        {
          text: "Ticket No",
          align: "center",
          sortable: false,
        },
        {
          text: "Entry gate",
          align: "center",
          sortable: false,
        },
        {
          text: "Exit gate",
          align: "center",
          sortable: false,
        },
        {
          text: "View",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  async mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    await self.getDataTransactionCarpark();
    await self.getMasConfigTerminal();
    await self.getDropdownSearchTerminal();
    await self.getTennant();
    await self.ExportTransactionCarpark();
  },

  methods: {
    print() {
      let self = this;
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        // document.body.appendChild(canvas)
        // console.log(document.body.appendChild(canvas));

        const base64 = canvas.toDataURL("image/png");

        // var myImage = canvas.toDataURL("image/png");
        var tWindow = window.open("");

        tWindow.document.body.appendChild(canvas);

        // focus on the window
        tWindow.focus();

        // print the window
        tWindow.print();

        // reload the page
        tWindow.close();

        // console.log(base64);
        self.base64 = base64;

        // self.ReSet();

        // window.print(self.base64);
        // self.printQr(self.base64);
        // var anchor = document.createElement("a");
        // anchor.setAttribute("href", base64);
        // anchor.setAttribute("download", "receipt.png");
        // anchor.click();
        // anchor.focus();
        // anchor.print();
        // anchor.remove();
      });
    },

    OpenQrImageindex(data) {
      let self = this;
      if (data != "") {
        // self.ShowImageText = data;
        self.qrImage = "https://carpark.onebangkok.com/info/" + data.logEntry;
        self.logId = data.logEntry;
        self.ticket = data.ticketNo;
        self.license = data.carNo;
        self.date = self.ChangeFormatDateOnly(data.entryDate);
        self.time = self.ChangeFormatTime(data.entryDate);
        self.ShowReprintImageDialog = true;
      }
    },

    OpenCardDialogReprint() {
      let self = this;
      self.DialogReprint = true;
    },

    OpenQrImage(data) {
      let self = this;
      if (data != "") {
        // self.ShowImageText = data;
        self.ShowReprintImageDialog = true;
      }
    },

    OpenImageCarpark(data) {
      let self = this;
      if (data != "") {
        self.ShowImageText = data;
        self.ShowImageDialog = true;
      }
    },

    CloseImageCarpark() {
      let self = this;
      self.ShowImageText = "";
      self.ShowImageDialog = false;
    },

    getTennant() {
      let self = this;
      axios
        .get(`${self.url}Tenant/getDataTenant`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.tenantArray = response.data.data;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getMasConfigTerminal() {
      let that = this;
      await axios
        .get(`${that.url}TransactionCarpark/getMasConfigTerminal`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ConfigTerminal = response.data.data;
            // console.log(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    async getDropdownSearchTerminal() {
      let that = this;
      await axios
        .get(`${that.url}TransactionCarpark/getDropdownSearchTerminal`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.DropdownConfigTerminal = response.data.data;
            that.DropdownTerminalIn = response.data.data.terminalIn;
            that.DropdownTerminalOut = response.data.data.terminalOut;
            console.log(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ConvertTerminalID(value) {
      let self = this;
      if (value != null) {
        return self.ConfigTerminal.filter((x) => x.terminalID == value) == null
          ? ""
          : self.ConfigTerminal.filter((x) => x.terminalID == value)[0] == null
          ? ""
          : self.ConfigTerminal.filter((x) => x.terminalID == value)[0]
              .terminalName;
      }
    },

    async getDataTransactionCarpark() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        logEntry: self.logEntry == "" ? null : self.logEntry,
        carNo: self.carNo == "" ? null : self.carNo,
        entryDate: self.entryDate == "" ? null : self.entryDate,
        exitDate: self.exitDate == "" ? null : self.exitDate,
        vehicleType:
          self.vehicleType == "" || self.vehicleType == null
            ? null
            : self.vehicleType == "Car"
            ? 0
            : 1,
        ticketNo: self.ticketNo == "" ? null : self.ticketNo,
        terminalEntry: self.terminalEntry == "" ? null : self.terminalEntry,
        tenantID: self.SearchTenant == "" ? null : self.SearchTenant,
        terminalIn: self.terminalIDIn == "" ? null : self.terminalIDIn,
        terminalOut: self.terminalIDOut === "" ? null : self.terminalIDOut,
        statusLog:
          self.statusLog == "" || self.statusLog == null
            ? null
            : self.statusLog == "ยังอยู่ในระบบ"
            ? 0
            : 1,
      };
      await axios
        .post(`${self.url}TransactionCarpark/getDataTransactionCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.ExportButtonRolePermission = response.data.data.roleID;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        logEntry: self.logEntry == "" ? null : self.logEntry,
        carNo: self.carNo == "" ? null : self.carNo,
        entryDate: self.entryDate == "" ? null : self.entryDate,
        exitDate: self.exitDate == "" ? null : self.exitDate,
        vehicleType:
          self.vehicleType == "" || self.vehicleType == null
            ? null
            : self.vehicleType == "Car"
            ? 0
            : 1,
        ticketNo: self.ticketNo == "" ? null : self.ticketNo,
        terminalEntry: self.terminalEntry == "" ? null : self.terminalEntry,
        tenantID: self.SearchTenant == "" ? null : self.SearchTenant,
        terminalIn: self.terminalIDIn == "" ? null : self.terminalIDIn,
        terminalOut: self.terminalIDOut === "" ? null : self.terminalIDOut,
        statusLog:
          self.statusLog == "" || self.statusLog == null
            ? null
            : self.statusLog == "ยังอยู่ในระบบ"
            ? 0
            : 1,
      };
      axios
        .post(`${self.url}TransactionCarpark/getDataTransactionCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        logEntry: self.logEntry == "" ? null : self.logEntry,
        carNo: self.carNo == "" ? null : self.carNo,
        entryDate: self.entryDate == "" ? null : self.entryDate,
        exitDate: self.exitDate == "" ? null : self.exitDate,
        vehicleType:
          self.vehicleType == "" || self.vehicleType == null
            ? null
            : self.vehicleType == "Car"
            ? 0
            : 1,
        ticketNo: self.ticketNo == "" ? null : self.ticketNo,
        terminalEntry: self.terminalEntry == "" ? null : self.terminalEntry,
        tenantID: self.SearchTenant == "" ? null : self.SearchTenant,
        terminalIn: self.terminalIDIn == "" ? null : self.terminalIDIn,
        terminalOut: self.terminalIDOut === "" ? null : self.terminalIDOut,
        statusLog:
          self.statusLog == "" || self.statusLog == null
            ? null
            : self.statusLog == "ยังอยู่ในระบบ"
            ? 0
            : 1,
      };
      axios
        .post(`${self.url}TransactionCarpark/getDataTransactionCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.DataList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.total / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    ChangeFormatDateOnly(date) {
      if (!date) return null;
      var DateData = new Date(date);
      // // var hours = DateData.getHours();
      // var minutes = DateData.getMinutes();
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },
    ChangeFormatTime(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      var sec = DateData.getSeconds();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + ":" + sec;
      return strTime;
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    async OpenDialogForce(log) {
      this.LoadingDialog = true;
      const { value: formValues } = await Swal.fire({
        showCloseButton: true,
        allowOutsideClick: false,
        confirmButtonColor: "#3085d6",
        title: "ปรับสถานะออก",
        html: `
              <input id="swal-input1" type="password" class="swal2-input" placeholder="Password">
              <p style="color: red;text-align: center;">*กรอกรหัสยืนยันตัวตน </p>

            `,
        focusConfirm: false,
        preConfirm: () => {
          return [document.getElementById("swal-input1").value];
        },
      });
      if (formValues) {
        // console.log(formValues);
        this.password = formValues[0];
      }
      if (this.password == "") {
        this.LoadingDialog = false;
        // Swal.fire({
        //   icon: "warning",
        //   title: "Please fill in this form.",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        return;
      } else {
        this.forceAntiPassBack(log);
      }
    },

    forceAntiPassBack(log) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        logEntry: log,
        password: that.password,
      };
      axios
        .post(`${that.url}TransactionCarpark/forceAntiPassBack`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.LoadingDialog = false;
            Swal.fire({
              icon: "success",
              title: "Success",
              showConfirmButton: false,
              timer: 1500,
            });
            that.getDataTransactionCarpark();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
      that.LoadingDialog = false;
    },

    getDataTransactionCarparkByLogID(id) {
      let that = this;
      that.LoadingDialog = true;
      that.DataListCarparkID = [];
      that.DataListImgID = [];
      let temp = {
        logid: id,
      };
      axios
        .post(
          `${that.urlCarpark}TransactionCarpark/getDataTransactionCarparkByLogID`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.DataListCarparkID = response.data.data.transaction;
            // that.DataListImgID = response.data.data.picture;
            that.BindingEditData(that.DataListCarparkID);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    BindingEditData(transaction) {
      let that = this;

      that.qrImage = "https://carpark.onebangkok.com/info/" + transaction.logID;
      that.logId = transaction.logID;
      that.ticket = transaction.ticketNo;
      that.license = transaction.carNo;

      that.date = that.ChangeFormatDateOnly(transaction.logDate);
      that.time = that.ChangeFormatTime(transaction.entryDate);

      that.DataListCarparkID.vehicleType =
        transaction.vehicleType == "0" ? "Car" : "Motorcycle";
      that.DataListCarparkID.logDate = that.ChangeFormatDate(
        transaction.logDate
      );
      that.DataListCarparkID.entryDate = that.ChangeFormatDate(
        transaction.entryDate
      );
      that.DataListCarparkID.parkTime =
        transaction.parkTime == ":" ? "" : transaction.parkTime;
      // console.log(picture);
      // if (picture.length > 0) {
      //   that.ImageList = picture;
      // }
      that.TransactionCarparkDialog = true;
      that.getDataTransactionCarparkByLogIDImage(that.logId);
    },

    getDataTransactionCarparkByLogIDImage(id) {
      let that = this;
      that.DataListImgID = [];
      that.ImageList = [];
      let temp = {
        logid: id,
      };
      axios
        .post(
          `${that.urlCarpark}TransactionCarpark/getDataTransactionCarparkImagesByLogID`,
          temp
        )
        .then(function (response) {
          if (response.data.status == 0) {
            // that.DataListCarparkID = response.data.data.transaction;
            that.DataListImgID = response.data.data;
            // console.log(that.DataListImgID);
            if (that.DataListImgID.length > 0) {
              that.ImageList = that.DataListImgID;
            }
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ExportTransactionCarpark() {
      let self = this;
      // self.LoadingDialog = true;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        logEntry: self.logEntry == "" ? null : self.logEntry,
        carNo: self.carNo == "" ? null : self.carNo,
        entryDate: self.entryDate == "" ? null : self.entryDate,
        exitDate: self.exitDate == "" ? null : self.exitDate,
        vehicleType:
          self.vehicleType == "" || self.vehicleType == null
            ? null
            : self.vehicleType == "Car"
            ? 0
            : 1,
        ticketNo: self.ticketNo == "" ? null : self.ticketNo,
        terminalEntry: self.terminalEntry == "" ? null : self.terminalEntry,
        tenantID: self.SearchTenant == "" ? null : self.SearchTenant,
        statusLog:
          self.statusLog == "" || self.statusLog == null
            ? null
            : self.statusLog == "ยังอยู่ในระบบ"
            ? 0
            : 1,
      };
      axios
        .post(`${self.url}TransactionCarpark/exportTransactionCarpark`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            // self.LoadingDialog = false;
            self.dataExport = response.data.data;
            // var tempdata = response.data.data;
            // const blob = self.base64ToBlob(tempdata, "text/csv;charset=utf-8;");
            // const linkSource = URL.createObjectURL(blob);
            // var link = document.createElement("a");
            // var date = new Date();
            // link.download =
            //   "TransactionCarparkReport_" +
            //   date.toLocaleString("th-TH") +
            //   ".csv";
            // link.href = linkSource;
            // link.click();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}
.header-table-center-css {
  text-align: center;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
