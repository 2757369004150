<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Manage Cards Carpark
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-menu
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menuStartDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-menu
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar"
                        outlined
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menuEndDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-select
                    :items="['Active', 'InActive']"
                    label="Status"
                    v-model="activeStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    v-on:keyup.enter="SearchCard(search)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-select
                    :items="['Expire', 'NotExpire']"
                    label="Status Expired"
                    v-model="expireStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" class="pt-5">
                  <v-select
                    :items="['Use', 'Not Use']"
                    label="Status Use"
                    v-model="useStatus"
                    required
                    clearable
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getDataCardCarparkList()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="permissionsPage.create"
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenCardDialog('create', null)"
                    >
                      REGISTER
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="4" lg="2" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      v-if="permissionsPage.create"
                      color="green"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="OpenCardDialog('create', null)"
                    >
                      REGISTER
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-hover>
                </v-col> -->
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="UserID == 1 ? headersAdmin : headers"
                :items="CardList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.cardNumber }}</td>
                    <!-- <td class="header-table-css">{{ item.cardUID }}</td> -->
                    <td class="header-table-css">{{ item.owner }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.startDate) }}
                    </td>

                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.expiredDate) }}
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.expire == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td style="text-align: center">
                      <img
                        v-if="item.use == true"
                        src="@/assets/correct.png"
                        width="20"
                        height="20"
                      />
                      <img
                        v-else
                        src="@/assets/close.png"
                        width="20"
                        height="20"
                      />
                    </td>
                    <td class="header-table-css">
                      {{ item.remark }}
                    </td>
                    <td class="header-table-css" v-if="UserID == 1">
                      {{ ChangeFormatDateTime(item.updateTime) }}
                    </td>
                    <td class="header-table-css" v-if="UserID == 1">
                      {{ item.updateBy }}
                    </td>
                    <td
                      style="
                        padding-bottom: 5px;
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                      "
                    >
                      <v-btn
                        :color="GetColor(item.active)"
                        rounded
                        class="text-capitalize"
                        depressed
                        style="width: 90px"
                      >
                        <span :style="{ color: GetColorText(item.active) }">{{
                          item.active == true ? "Active" : "InActive"
                        }}</span>
                      </v-btn>
                    </td>
                    <td style="text-align: center; width: 150px">
                      <v-btn
                        v-if="permissionsPage.update"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenCardDialog('edit', item)"
                      >
                        <v-icon dark> mdi-pencil-outline </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="permissionsPage.delete"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                        @click="inActiveCardByCardID(item.id)"
                      >
                        <v-icon dark>mdi-trash-can-outline </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4"></v-col>

                <v-col cols="12" md="4"></v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog v-model="CardDialog" persistent max-width="750px" scrollable>
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span v-if="EditStatus" class="text-h5">Edit Card</span>
          <span v-else class="text-h5">Create Card</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Card Number*"
                      v-model="cardNumber"
                      type="number"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      @click="GenerateCardNumer()"
                    >
                      Virtual Card
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-switch
                      v-model="active"
                      hide-details
                      inset
                      :label="active ? 'Active' : 'InActive'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuStartDateEvent"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDateEvent"
                          label="Start Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDateEvent"
                        @change="menuStartDateEvent = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menuExpireDateEvent"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="ExpireDateEvent"
                          label="Expire Date"
                          append-icon="mdi-calendar"
                          dense
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="ExpireDateEvent"
                        @change="menuExpireDateEvent = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-autocomplete
                      :items="Member_Type"
                      clearable
                      v-model="memberType"
                      item-text="type_name"
                      item-value="id"
                      label="Member Type*"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Monthly Pay"
                      v-model="monthlyPay"
                      type="number"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-checkbox
                      v-model="blacklistStatus"
                      :label="`Blacklist Status`"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <v-checkbox
                      v-model="lostStatus"
                      :label="`Lost Status`"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Remark" v-model="remark" required>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="12">
                <v-divider></v-divider>
                <v-row class="mt-4">
                  <v-col cols="12" sm="6" md="6">
                    <p
                      class="pt-5"
                      style="
                        color: #444444;
                        font-size: 18px;
                        font-weight: regular;
                      "
                    >
                      License Plate
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" style="text-align: end">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      @click="showLicence('create', null)"
                    >
                      Add
                      <v-icon right> mdi-plus </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="headersDropdown"
                  :items="cardLicenceList"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.provinceName="{ item }">
                    {{ convertProvince(item) }}
                  </template>
                  <template v-slot:item.vehicleName="{ item }">
                    {{ convertVehicleType(item) }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn text @click="showLicence('edit', item)">
                      <span class="mdi mdi-pencil"></span>
                      <span style="color: #ff4839" class="ml-1 text-capitalize"
                        >Edit</span
                      >
                    </v-btn>
                    <v-btn text @click="DeleteDataDropdown(item)">
                      <img src="@/assets/close.png" width="15" height="15" />
                      <span style="color: #ff4839" class="ml-1 text-capitalize"
                        >Delete</span
                      >
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                v-if="EditStatus == false"
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addCard()"
              >
                Save
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                style="min-width: 100px"
                color="success"
                @click="addCard()"
              >
                Update
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="CardDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="Licencedialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span v-if="plateStatus">Add Licence Plate</span>
          <span v-else>Edit Licence Plate</span>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field label="ทะเบียน*" v-model="licencePlate" required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field label="ยี่ห้อ" v-model="brand" required>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field label="สี" v-model="color" required> </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="ProvinceList"
                item-value="provinceID"
                item-text="type_name"
                label="จังหวัด*"
                v-model="province"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="vehicleType"
                item-value="code"
                item-text="name"
                label="ประเภทรถ*"
                v-model="vehicle"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions style="display: flex; justify-content: flex-end">
          <v-btn color="success" @click="addLicencePlate()"> Save</v-btn>
          <v-btn color="primary" @click="Licencedialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "ManageCardCarpark",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      active: false,
      useFace: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",
      cardNumber: "",
      cardId: "",
      CardList: [],
      getCardsAccessGroup: [],
      getCardsAccessGroupList: [],
      search: "",
      searchAccess: "",
      tagGroupName: "",
      activeStatus: null,
      startDate: "",
      endDate: "",
      startDateEvent: "",
      ExpireDateEvent: "",
      menuStartDate: false,
      menuEndDate: false,
      menuStartDateEvent: false,
      menuExpireDateEvent: false,
      CardDialog: false,
      EditStatus: false,
      ChooseLocationBTN: false,
      Licencedialog: false,
      blacklistStatus: false,
      lostStatus: false,
      UserID: null,
      plateStatus: true,

      // Add Edit
      id: 0,
      cardID: 0,
      monthlyPay: "0",
      CardName: "",
      CardNameTH: "",
      remark: "",
      Status: true,

      pageFloor: 1,
      itemFloor: 10,
      lengthFloor: 1,
      totalPage: 10,

      SearchData: null,
      cardLicenceList: [],
      cardAccessGroupListTemp: [],
      InputListDataDropdown: null,
      Member_Type: [],
      memberType: "",
      ProvinceList: [],
      province: "",
      indexLicense: 0,
      idLicense: null,

      useStatus: null,
      expireStatus: null,

      vehicleType: [
        {
          code: 0,
          name: "Car",
        },
        {
          code: 1,
          name: "Motorcycle",
        },
      ],

      vehicle: null,

      licencePlate: "",
      brand: "",
      color: "",
      permissionsPage: [],

      headersDropdown: [
        {
          text: "ทะเบียน",
          align: "left",
          value: "licencePlate",
          sortable: false,
        },
        {
          text: "ยี่ห้อ",
          align: "left",
          value: "brand",
          sortable: false,
        },
        {
          text: "สีรถ",
          align: "center",
          value: "color",
          sortable: false,
        },
        {
          text: "จังหวัด",
          align: "left",
          value: "provinceName",
          sortable: false,
        },
        {
          text: "ประเถทรถ",
          align: "left",
          value: "vehicleName",
          sortable: false,
        },
        {
          text: "จัดการ",
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "CardNumber",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "CardUID",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Owner",
          align: "left",
          sortable: false,
        },

        {
          text: "StartDate",
          align: "left",
          sortable: false,
        },
        {
          text: "ExpireDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Status EXP",
          align: "center",
          sortable: false,
        },
        {
          text: "Status Use",
          align: "center",
          sortable: false,
        },
        {
          text: "ExpireDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Remark",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "CreateTime",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersAdmin() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "CardNumber",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "CardUID",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Owner",
          align: "left",
          sortable: false,
        },

        {
          text: "StartDate",
          align: "left",
          sortable: false,
        },
        {
          text: "ExpireDate",
          align: "left",
          sortable: false,
        },
        {
          text: "Status EXP",
          align: "center",
          sortable: false,
        },
        {
          text: "Status Use",
          align: "center",
          sortable: false,
        },
        {
          text: "Remark",
          align: "left",
          sortable: false,
        },
        {
          text: "LastUpdateTime",
          align: "left",
          sortable: false,
        },
        {
          text: "LastUpdateBy",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
    headersAccess() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "Card Number",
          align: "left",
          sortable: false,
        },
        {
          text: "UseFace",
          align: "left",
          sortable: false,
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    InputListDataDropdown: function (data) {
      let self = this;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardCarparkList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.$refs.refSearchData.focus();
        })
        .catch(function (error) {
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchData() {
      this.$refs.inputCardNumber.reset();
    },
  },

  async mounted() {
    let self = this;

    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    await self.getDataCardCarparkList();
    await self.GetCardsAccessGroup();
    await self.getMas_Member_Type();
    await self.getMas_Province();
  },

  methods: {
    GenerateCardNumer() {
      let self = this;
      axios
        .get(`${self.url}Card/generatCardNumberCarpark`)
        .then(function (response) {
          if (response.data.status == 0) {
            self.cardNumber = response.data.data;
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    async GetCardsAccessGroup() {
      let that = this;
      // that.LoadingDialog = true;
      await axios
        .get(`${that.url}Card/getCardsAccessGroup`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.getCardsAccessGroup = response.data.data;

            // that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // that.LoadingDialog = false;
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    convertProvince(value) {
      let data = "";
      if (value.province != "") {
        data = this.ProvinceList.find(
          (a) => a.provinceID == value.province
        ).type_name;
        return data;
      } else {
        return "";
      }
    },
    convertVehicleType(value) {
      let data = "";
      data = this.vehicleType.find((a) => a.code == value.vehicleType).name;
      return data;
    },

    addLicencePlate() {
      // console.log("addLicencePlate");
      this.cardLicenceList =
        this.cardLicenceList == null ? [] : this.cardLicenceList;

      var CheckDuplicate = this.cardLicenceList.find(
        (x) =>
          x.licencePlate == this.licencePlate && x.province == this.province
      );
      // console.log(this.licencePlate);
      if (this.licencePlate == "" || this.province == "") {
        Swal.fire("Please fill data completely");
      } else {
        if (CheckDuplicate != null && this.plateStatus) {
          Swal.fire("Duplicate Data !");
        } else {
          if (this.plateStatus == true) {
            let temp = {
              licencePlate: this.licencePlate,
              brand: this.brand,
              color: this.color,
              province: this.province,
              vehicleType: this.vehicle,
              active: true,
            };
            this.cardLicenceList.push(temp);
          } else {
            this.cardLicenceList[this.indexLicense].id = this.idLicense;
            this.cardLicenceList[this.indexLicense].licencePlate =
              this.licencePlate;
            this.cardLicenceList[this.indexLicense].brand = this.brand;
            this.cardLicenceList[this.indexLicense].color = this.color;
            this.cardLicenceList[this.indexLicense].province = this.province;
            this.cardLicenceList[this.indexLicense].vehicleType = this.vehicle;
          }
        }
      }

      this.licencePlate = "";
      this.brand = "";
      this.color = "";
      this.province = "";
      this.vehicle = null;
      // this.provinceName = "";
      // this.vehicleName = "";
      this.Licencedialog = false;
    },

    showLicence(action, data) {
      if (action == "create") {
        this.plateStatus = true;
        this.licencePlate = "";
        this.brand = "";
        this.color = "";
        this.province = "";
        this.vehicle = null;
        this.provinceName = "";
        this.vehicleName = "";
        this.Licencedialog = true;
      } else {
        this.indexLicense = this.cardLicenceList.indexOf(data);
        this.plateStatus = false;
        this.idLicense = data.id;
        this.licencePlate = data.licencePlate;
        this.brand = data.brand;
        this.color = data.color;
        this.province = data.province;
        this.vehicle = data.vehicleType;
        this.provinceName = data.provinceName;
        this.vehicleName = data.vehicleName;
        this.Licencedialog = true;
      }
    },

    async getMas_Member_Type() {
      let that = this;
      // that.LoadingDialog = true;
      await axios
        .get(`${that.url}Card/getMas_Member_Type`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.Member_Type = response.data.data;

            // that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // that.LoadingDialog = false;
        })
        .catch(function (error) {
          // that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    async getMas_Province() {
      let that = this;
      // that.LoadingDialog = true;
      await axios
        .get(`${that.url}Card/getMas_Province`)
        .then(function (response) {
          if (response.data.status == 0) {
            that.ProvinceList = response.data.data;

            // that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          // that.LoadingDialog = false;
        })
        .catch(function (error) {
          // that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    async getDataCardCarparkList() {
      let self = this;
      self.LoadingDialog = true;
      // self.page = 1;
      let temp = {
        page: self.page,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      await axios
        .post(`${self.url}Card/getDataCardCarparkList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.count / self.itemsPerPage
            );
            // self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            self.LoadingDialog = false;
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardCarparkList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.count / self.itemsPerPage
            );
            // self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardCarparkList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.count / self.itemsPerPage
            );
            // self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchCard(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
        expire:
          self.expireStatus == "Expire"
            ? true
            : self.expireStatus == null
            ? null
            : false,
        use:
          self.useStatus == "Use"
            ? true
            : self.useStatus == null
            ? null
            : false,
        active:
          self.activeStatus == "Active"
            ? true
            : self.activeStatus == null
            ? null
            : false,
      };
      axios
        .post(`${self.url}Card/getDataCardCarparkList`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.CardList = response.data.data.listData;
            self.pageCount = response.data.data.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
            self.UserID = response.data.data.userID;
            self.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      // var hours = DateData.getHours();
      // var minutes = DateData.getMinutes();
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      // var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
        //  +
        // "  " +
        // strTime
      );
    },

    ChangeFormatDateTime(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    checkVakidates() {
      let that = this;
      if (that.cardNumber == "" || that.memberType == "") {
        return true;
      } else {
        return false;
      }
    },

    addCard() {
      let that = this;

      if (that.checkVakidates()) {
        Swal.fire({
          icon: "warning",
          title: "กรุณากรอกข้อมูลให้ครบถ้วน",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      that.LoadingDialog = true;

      if (!that.EditStatus) {
        let temp = {
          cardNumber: that.cardNumber,
          startDate: that.startDateEvent,
          expiredDate: that.ExpireDateEvent,
          memberType: that.memberType,
          monthlyPay: that.monthlyPay == null ? 0 : that.monthlyPay,
          remark: that.remark,
          blacklistStatus: that.blacklistStatus,
          lostStatus: that.lostStatus,
          cardLicenceList: that.cardLicenceList,
          active: that.active,
        };
        axios
          .post(`${that.url}Card/addCardCarpark`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 1) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "warning",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }
            that.page = 1;
            that.getDataCardCarparkList();
            that.CardDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      } else {
        let temp = {
          id: that.id,
          cardNumber: that.cardNumber,
          startDate: that.startDateEvent,
          expiredDate: that.ExpireDateEvent,
          memberType: that.memberType,
          monthlyPay: that.monthlyPay == null ? 0 : that.monthlyPay,
          blacklistStatus: that.blacklistStatus,
          lostStatus: that.lostStatus,
          remark: that.remark,
          cardLicenceList: that.cardLicenceList,
          active: that.active,
        };
        axios
          .post(`${that.url}Card/editCardCarpark`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "success",
                title: "Success",
                showConfirmButton: false,
                timer: 1500,
              });
            }
            if (response.data.status == 1) {
              that.LoadingDialog = false;
              Swal.fire({
                icon: "warning",
                title: response.data.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
            if (response.data.status == 2) {
              eventBus.$emit("logout");
            }

            that.getDataCardCarparkList();
            that.CardDialog = false;
          })
          .catch(function (error) {
            that.LoadingDialog = false;
            if (error.response.status != 404) {
              Swal.fire({
                icon: "error",
                title: "Error...",
                width: 900,
                text: error.response.data.message,
              });
            }
          });
      }
    },

    GetColor(value) {
      if (value == "1") {
        return "rgb(92, 184, 92,0.3)";
      } else {
        return "rgb(232, 34, 34,0.3)";
      }
    },
    // GetStatue(value) {
    //   if (value == 0) {
    //     return "Completed";
    //   } else if (value == 1) {
    //     return "process";
    //   } else {
    //     return "Error";
    //   }
    // },

    GetColorText(value) {
      if (value == true) {
        return "rgb(92, 184, 92)";
      } else {
        return "rgb(232, 34, 34)";
      }
    },

    OpenCardDialog(module, data) {
      let self = this;
      if (module == "create") {
        self.EditStatus = false;
        self.cardNumber = "";
        self.startDateEvent = "";
        self.ExpireDateEvent = "";
        self.memberType = "";
        self.remark = "";
        self.cardLicenceList = [];
        (self.blacklistStatus = false),
          (self.lostStatus = false),
          (self.monthlyPay = "0"),
          (self.active = true);
        self.CardDialog = true;
      } else {
        // self.BindingEditData(data);
        self.getDataCardByCardID(data.id);
      }
    },

    BindingEditData(data) {
      let self = this;
      self.EditStatus = true;
      self.id = data.id;
      self.cardNumber = data.cardNumber;
      self.startDateEvent =
        data.startDate == null ? "" : self.ConvertDate(data.startDate);
      self.ExpireDateEvent =
        data.expiredDate == null ? "" : self.ConvertDate(data.expiredDate);
      self.cardLicenceList = data.vehicleList;

      self.memberType = data.memberType;
      self.monthlyPay = data.monthlyPay;
      self.remark = data.remark;
      self.blacklistStatus = data.blacklistStatus;
      self.lostStatus = data.lostStatus;
      self.active = data.active;
      self.CardDialog = true;
    },

    getDataCardByCardID(id) {
      let that = this;
      let temp = {
        cardID: id,
      };
      axios
        .post(`${that.url}Card/getDataCardCarparkByID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            that.BindingEditData(response.data.data);
            // that.tenantArray = response.data.data;
            // that.LoadingDialog = false;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    inActiveCardByCardID(id) {
      let that = this;
      that.LoadingDialog = true;
      let temp = {
        Id: id,
      };

      Swal.fire({
        title: "InActive",
        text: "Do you want to InActive this Role ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`${that.url}Card/inActiveCardCarpark`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                that.getDataCardCarparkList();
                Swal.fire(
                  "Deleted!",
                  "Your file has been Inactive.",
                  "success"
                );
                that.getDataCardCarparkList();
              }
              if (response.data.status == 2) {
                eventBus.$emit("logout");
              }
              that.LoadingDialog = false;
            })
            .catch(function (error) {
              that.LoadingDialog = false;
              if (error.response.status != 404) {
                Swal.fire({
                  icon: "error",
                  title: "Error...",
                  width: 900,
                  text: error.response.data.message,
                });
              }
            });
          that.LoadingDialog = false;
        }
        that.LoadingDialog = false;
      });
    },

    AddDataDropdown(val) {
      let self = this;
      if (val != null) {
        var CheckDuplicate = this.cardLicenceList.find(
          (x) => x.tagGroupNo == val.tagrP_NO
        );
        if (CheckDuplicate != null) {
          Swal.fire("Duplicate Data !");
        } else {
          var temp = {
            projectID: val.projectID,
            projectName: val.projectName,
            cardNumber: self.cardNumber,
            towerID: val.towerID,
            towerName: val.towerName,
            tagGroupNo: val.tagrP_NO,
            tagGroupName: val.tagrP_Name,
            active: true,
          };
          self.cardLicenceList.push(temp);
          self.cardAccessGroupListTemp = self.cardLicenceList;
          // self.lengthFloor = Math.ceil(self.cardLicenceList.length / 5);
        }
      }
    },

    DeleteDataDropdown(item) {
      let self = this;
      // var del = Swal.fire("Are you sure you want to delete !");
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var Index = self.cardLicenceList.indexOf(item);
          self.cardLicenceList.splice(Index, 1);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        }
      });
    },

    ChangeFloorPage(value) {
      let that = this;
      that.cardLicenceList = that.paginate(
        that.cardAccessGroupListTemp,
        5,
        value
      );
    },
    ConvertDate(value) {
      // console.log(value);
      if (value != "") {
        return value.split("T")[0];
      }
      // let that = this;
      // that.cardLicenceList = that.paginate(that.cardAccessGroupListTemp, 5, value);
    },
    paginate(array, page_size, page_number) {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.classBgRed {
  background-color: rgba(92, 184, 92, 0.3);
}
.classBgWhite {
  background-color: white;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
