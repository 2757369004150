import { render, staticRenderFns } from "./TransactionRedemption.vue?vue&type=template&id=7f7bb2b1&scoped=true"
import script from "./TransactionRedemption.vue?vue&type=script&lang=js"
export * from "./TransactionRedemption.vue?vue&type=script&lang=js"
import style0 from "./TransactionRedemption.vue?vue&type=style&index=0&id=7f7bb2b1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f7bb2b1",
  null
  
)

export default component.exports